(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('openOrderPL', {
                parent: 'screen',
                url: '/open-order-pl',
                component: 'openOrderPL',
                data: {
                    permissions: ['openOrderPl']
                }
            });
        }
    ]);

})(angular);
