(function (angular) {
  "use strict";

  angular.module('myApp').component("seoInputs", {
    templateUrl: "/core/components/groups/seoInputs/seo-inputs.tpl.html",
    transclude: true,
    bindings: {
      service: '='
    }
  });

}(window.angular));
