(function (angular) {
    "use strict";

    angular.module('myApp').component("textboxDate", {
        templateUrl: "/core/components/form/textboxDate/textbox-date.tpl.html",
        transclude: true,
        bindings: {
            name: '@',
            required: '@',
            label: '@?',
            service: '=',
            change: '&',
            textId:'@'
        },
        controller: ['$scope','$timeout',
            function ($scope, $timeout) {
                var $that = this;
                this.$onInit = function () {
                    
                    $timeout(function () {
                        $('#' + $that.textId).datetimepicker({
                            format: "dd/mm/yyyy",
                            todayHighlight: true,
                            autoclose: true,
                            startView: 2,
                            minView: 2,
                            forceParse: 0,
                            pickerPosition: 'bottom-right'
                        })
                        .change(dateChanged);
                    }, 500);
                };

                function dateChanged(ev) {
                    $that.service.dto[$that.name] = $('#' + ev.target.id).val();
                    $scope.$apply();
                }
            }
        ]
    });

}(window.angular));
