(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {
            
            $stateProvider.state('purchaseOrders', {
                parent: 'screen',
                url: '/purchase-orders',
                component: 'purchaseOrders',
                data: {
                    permissions: ['orders']
                }
            });

        }
    ]);

})(angular);
