(function (angular) {
    "use strict";

    angular.module('myApp').factory('rishviCreate', [
        "$state", "$window",
        function ($state, $window) {

            var rishviCreate = function () {

                this.listRoute = null;
                this.editRoute = null;

                this.service = null;

                this.result = {};
                this.dto = {};

                this.isSubModule = false;

                // setTimeout(function () { funInputPlaceholder(); }, 500);

                this.onBeforeSave = null;
            };

            rishviCreate.prototype.save = function () {
                var $that = this;

                if (typeof $that.onBeforeSave === "function")
                    $that.onBeforeSave();
              
                $that.service.create($that.dto)
                    .then(function (resp) {

                        $that.result = resp.data;
                        Swal.fire({
                            position: "top-right",
                            icon: resp.data.messageType === 1 ? "success" : "error",
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        });

                        //flash.show(resp.data.message, resp.data.messageType);

                        if (resp.data.success) {
                          $state.go($that.listRoute);
                        }
                        //if (resp.data.success) {
                        //    $that.dto = {};
                        //    //$that.dto.isActive = true;
                        //    $state.reload();
                        //}

                        $window.scrollTo(0, 0);
                    });
            };

            rishviCreate.prototype.saveContinue = function () {
                var $that = this;

                if (typeof $that.onBeforeSave === "function")
                    $that.onBeforeSave();

                this.service.create(this.dto)
                    .then(function (resp) {

                        $that.result = resp.data;
                        Swal.fire({
                            position: "top-right",
                            icon: resp.data.messageType === 1 ? "success" : "error",
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        //flash.show(resp.data.message, resp.data.messageType);

                        if (resp.data.success) {
                            if ($that.isSubModule) {
                                $state.go($that.editRoute, {
                                    childId: resp.data.id
                                });
                            } else {
                                $state.go($that.editRoute, {
                                    id: resp.data.id
                                });
                            }
                        }

                        $window.scrollTo(0, 0);
                    });
            };

            rishviCreate.prototype.saveNew = function () {
                var $that = this;

                if (typeof $that.onBeforeSave === "function")
                    $that.onBeforeSave();

                this.service.create(this.dto)
                    .then(function (resp) {

                        $that.result = resp.data;
                        Swal.fire({
                            position: "top-right",
                            icon: resp.data.messageType === 1 ? "success" : "error",
                            title: resp.data.message,
                            showConfirmButton: false,
                            timer: 2000
                        });
                        //flash.show(resp.data.message, resp.data.messageType);

                        if (resp.data.success) {
                            $that.dto = {};
                            $that.dto.isActive = true;
                            $state.reload();
                        }

                        $window.scrollTo(0, 0);
                    });
            };

            return rishviCreate;
        }
    ]);

}(window.angular));
