(function (angular) {
    "use strict";

    angular.module('myApp').component('amazonPL',
        {
            templateUrl: '/amazonPL/views/amazonPL.tpl.html',
            controller: ['$scope', 'rishviList', 'amazonPLService',
                function ($scope, rishviList, amazonPLService) {
                    $scope.list = new rishviList();
                    $scope.list.url = 'api/linnwork-amazonpl/get-amazonpl-list';
                    $scope.list.module = 'amazonpl';
                    $scope.list.filters.filterName = "General;object:186";
                    $scope.list.load();

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };
                    $scope.searchAmazonPL = function () {
                        debugger;
                        //var searchDate = $('#searchDate').val().trim();

                        //if (searchDate !== "" && searchDate !== undefined && searchDate !== null) {
                        //    var dateFrom = searchDate.split('-')[0].split('/');
                        //    var dateTo = searchDate.split('-')[1].split('/');
                        //    $scope.list.filters.fromDateFilterDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        //    $scope.list.filters.toDateFilterDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        //}

                        $scope.list.load();
                    };
                    $scope.allSource = [];
                    $scope.allSubSource = [];

                    amazonPLService.getAllSources().then(function (resp) {
                        $scope.allSource = resp.data;
                    });

                    $scope.sourceChange = function () {
                        amazonPLService.getAllSubSources($scope.list.filters.source).then(function (resp) {
                            $scope.allSubSource = resp.data;
                        });
                    };
                }]
        });

}(window.angular));
