(function (angular) {
  "use strict";

  angular.module('myApp').directive("toolbar",
    function () {
      return {
        scope: {
          model: '=',
          //transclude: true
          //paging: '@?',
          permissions: '@?'
        },
        transclude: true,
        templateUrl: "/core/components/toolbar/toolbar.tpl.html",
        controller: ["$scope", "$attrs", "$rootScope", "localStorageService", function ($scope, $attrs, $rootScope, localStorageService) {
          $scope.isAccess = $rootScope.hasAccess($attrs.permissions);

          $scope.hasAccess = function (permissions) {
            return $rootScope.hasAccess(permissions);
          };

          $scope.pageSizeChanged = function(size) {
            localStorageService.set('admin.page.size', size);
          };
        }]
      };
    });

}(window.angular));
