(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('profitLossService',
            [
                '$http', function ($http) {

                }
            ]);

}(window.angular));