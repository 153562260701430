(function (angular) {
    "use strict";

    angular.module('myApp').component('purchaseOrders',
        {
            templateUrl: '/purchaseOrders/views/purchaseOrders.tpl.html',
            controller: ['$scope', '$state', 'rishviList', 'purchaseOrderService', 'categoryDutyService',
                function ($scope, $state, rishviList, purchaseOrderService, categoryDutyService) {
           
                    $scope.purchaseOrderFilterDto = {};
                    var start = moment().subtract(30, 'days');
                    var end = moment();

                    $('#purchaseOrderDateRange').daterangepicker({
                        buttonClasses: ' btn',
                        applyClass: 'btn-primary',
                        cancelClass: 'btn-secondary',

                        startDate: start,
                        endDate: end,
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Last 3 Months': [moment().subtract(3, 'month'), moment().subtract(0, 'month')],
                            'Last 6 Months': [moment().subtract(6, 'month'), moment().subtract(0, 'month')],
                            'Last 1 Year': [moment().subtract(1, 'year'), moment().subtract(0, 'year')]
                        }
                    }, function (start, end, label) {
                            $('#purchaseOrderDateRange .form-control').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
                    });

                    $('#searchDate').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));


                    $scope.list = new rishviList();
                    $scope.list.url = 'api/purchase-orders/get-purchase-order-list';
                    $scope.list.module = 'purchaseOrders';

                    var searchDate = $('#searchDate').val();
                    var dateFrom = searchDate.split('-')[0].split('/');
                    var dateTo = searchDate.split('-')[1].split('/');
                    $scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                    $scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                    $scope.list.filters.expression = 'Equal';
                    $scope.list.filters.orderSuggession = '90';
                    $scope.allCategory = [];
                    $scope.allSupplier = [];

                    categoryDutyService.all().then(function (resp) {
                        $scope.allCategory = resp.data;
                    });

                    categoryDutyService.getSuppliers().then(function (resp) {
                        $scope.allSupplier = resp.data;
                    });

                    $scope.changeSearchCriteria = function () {
                        $scope.list.filters.searchTerm = '';
                        if ($scope.list.filters.searchCriteria === 'Category' || $scope.list.filters.searchCriteria === 'Supplier') {
                            $scope.list.filters.expression = 'Equal';
                        }
                    };

                    $scope.list.load();

                    $("#orderSuggession").keypress(function (e) {
                        //if the letter is not digit then display error and don't type anything
                        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                            
                            return false;
                        }
                    });

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                    $scope.searchPurchaseOrders = function () {
                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                        $scope.list.load();
                    };

                    $scope.exportToExcel = function () {
                        debugger;
                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        $scope.list.filters.sortColumn = 'Title';
                        $scope.list.filters.sortType = 'ASC';
                        purchaseOrderService.exportToExcel($scope.list.filters).then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    };
                }]
        });

}(window.angular));
