(function (angular) {
    "use strict";

    angular.module('myApp').component('inventory',
        {
            templateUrl: '/inventory/views/inventory.tpl.html',
            controller: ['$scope', 'rishviList', 'inventoryService',
                function ($scope, rishviList, inventoryService) {

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/linnwork-inventory/get-inventory-list';
                    $scope.list.module = 'inventory';
                    $scope.list.filters.filterName = "General;object:186";
                    $scope.list.load();

                    $scope.imageRemoveTumbnail = function (imageURL) {
                        debugger;
                        if (imageURL) {
                            return imageURL.replace('tumbnail_', '');
                        }
                        else {
                            return "";
                        }
                    };

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                    $scope.filterOptionChange = function () {
                        if ($scope.list.filters.filterName === "General;object:186") {
                            $scope.list.filters.value = "";
                        }
                    }

                    $scope.searchInventory = function () {
                        $scope.list.load(true);
                    }

                    $scope.exportToExcel = function () {
                        $scope.filterData = {};
                        $scope.filterData.filterName = $scope.list.filters.filterName;
                        $scope.filterData.value = $scope.list.filters.value;
                        inventoryService.exportToExcel($scope.filterData).then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    }


                }]
        });

}(window.angular));
