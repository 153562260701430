(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('amazonPL', {
                parent: 'screen',
                url: '/amazon-pl',
                component: 'amazonPL'
            }).state('reStock', {
                parent: 'screen',
                url: '/restock',
                component: 'reStock'
            });
        }
    ]);

})(angular);
