(function (angular) {
    "use strict";

    angular.module('myApp').component('changePassword',
        {
            templateUrl: '/account/views/change-password.tpl.html',
            controller: 'ChangePasswordController'
        });

    angular.module('myApp').controller('ChangePasswordController',
        [
            '$scope', '$http', 'accountService',
            function ($scope, $http, accountService) {

                $scope.rishvi  = {};
                $scope.rishvi.dto = {};
                $scope.rishvi.result = {};     

                $scope.submit = function () {
                    accountService.changePassword($scope.rishvi.dto)
                        .then(function (resp) {

                            $scope.rishvi.result = resp.data;

                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 2000
                            });

                            if (resp.data.success) {
                                $scope.rishvi.dto = {};
                            }
                        });
                };
            }
        ]);

}(window.angular));