(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('amazonPLService', [
            '$http',
            function ($http) {
                var urlBase = 'api/linnwork-amazonpl/'

                this.getInventoryList = function (filters) {
                    return $http({
                        url: urlBase + 'get-amazonpl-list',
                        params: filters,
                        method: 'GET'
                    });
                };

                this.exportToExcel = function (dto) {
                    return $http({
                        url: urlBase + 'export-amazonpl',
                        method: 'POST',
                        data: dto
                    });
                };
                this.getAllSources = function () {
                    return $http({
                        url: urlBase + 'get-all-source',
                        method: 'GET'
                    });
                };

                this.getAllSubSources = function (source) {
                    return $http({
                        url: urlBase + 'get-all-sub-source-by-source/' + source,
                        method: 'GET'
                    });
                }
            }
        ]);

}(window.angular));
