(function (angular) {
    "use strict";

    angular.module('myApp').component('shippingServiceEdit', {
        templateUrl: '/shippingService/views/edit.tpl.html',
        controller: 'shippingServiceEditController'
    });

    angular.module('myApp').controller('shippingServiceEditController',
        [
            '$scope', 'shippingServiceService', 'vendorsService', 'rishviEdit', '$timeout',
            function ($scope, shippingServiceService, vendorsService, rishviEdit, $timeout) {

                $scope.rishvi = new rishviEdit();
                $scope.rishvi.service = shippingServiceService;
                $scope.rishvi.listRoute = 'shippingService';
                $scope.rishvi.load();

                vendorsService.all().then(function (resp) {
                    $scope.vendors = resp.data;
                });

                $scope.formats = [
                    {
                        "id": "L",
                        "name": "L"
                    },
                    {
                        "id": "LL",
                        "name": "LL"
                    },
                    {
                        "id": "TRK",
                        "name": "TRK"
                    },
                    {
                        "id": "PA",
                        "name": "PA"
                    },
                    {
                        "id": "LP",
                        "name": "LP"
                    }
                ];
            }
        ]);

}(window.angular));
