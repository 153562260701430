(function (angular) {
    "use strict";

    angular.module('myApp').component('adminRolesEdit', {
        templateUrl: '/adminRoles/views/edit.tpl.html',
        controller: 'adminRolesEditController'
    });

    angular.module('myApp').controller('adminRolesEditController',
        [
            '$scope', 'adminRolesService', 'adminPermissionsService', 'rishviEdit', '$filter',
            function ($scope, adminRolesService, adminPermissionsService, rishviEdit, $filter) {

                $scope.editMode = true;

                $scope.rishvi = new rishviEdit();
                $scope.rishvi.service = adminRolesService;
                $scope.rishvi.listRoute = 'adminRoles';
                $scope.rishvi.load();

                adminPermissionsService.all().then(function (resp) {
                    $scope.permissions = unflatten(resp.data);
                    setTimeout(function () {
                        checkUncheckRolePermission();
                    },
                        400);
                });

                //$scope.collapseAll = function () {
                //    $scope.$broadcast('angular-ui-tree:collapse-all');
                //};

                //$scope.expandAll = function () {
                //    $scope.$broadcast('angular-ui-tree:expand-all');
                //};

                function checkUncheckRolePermission() {
                    $('div.checkbox1 :checkbox').on('click',
                        function () {
                            var $chk = $(this),
                                $li = $chk.closest('li');
                            $li.find(':checkbox').prop('checked', this.checked);

                            $scope.rishvi.dto.permissions = $('div.checkbox1 :checkbox:checked').map(function () {
                                return $(this).attr('id');
                            }).get();
                        });
                }
            }
        ]);

}(window.angular));
