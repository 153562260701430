(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('calculatorCreate', {
                parent: 'screen',
                url: '/calculator-create',
                component: 'calculatorCreate',
                data: {
                    permissions: ['calculator.create']
                }
            })
            .state('calculatorEdit', {
               parent: 'screen',
               url: '/calculator/edit/{id}',
               component: 'calculatorEdit',
               data: {
                   permissions: ['calculator.edit']
               }
            });

            $stateProvider.state('calculatorView', {
                parent: 'screen',
                url: '/calculator-view',
                redirectTo: 'pendingCalculator',
                component: 'calculatorView',
                data: {
                    permissions: ['calculator']
                }
            })
                .state('pendingCalculator', {
                    parent: 'calculatorView',
                    url: '/pending-calculator',
                    component: 'pendingCalculator',
                    data: {
                        permissions: ['calculator']
                    }
                })
                .state('approvedCalculator', {
                    parent: 'calculatorView',
                    url: '/approved-calculator',
                    component: 'approvedCalculator',
                    data: {
                        permissions: ['calculator']
                    }
                })
                .state('rejectedCalculator', {
                    parent: 'calculatorView',
                    url: '/rejected-calculator',
                    component: 'rejectedCalculator',
                    data: {
                        permissions: ['calculator']
                    }
                });
        }
    ]);

})(angular);
