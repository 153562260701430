(function (angular) {
    "use strict";

    angular.module('myApp').component('usersCreate',
        {
            templateUrl: '/users/views/userCreate.tpl.html',
            controller: ['$scope', '$state', 'userService','adminRolesService', 'rishviCreate',
                function ($scope, $state, userService, adminRolesService, rishviCreate) {

                    $scope.rishvi = new rishviCreate();
                    $scope.rishvi.service = userService;
                    $scope.rishvi.listRoute = 'users';
                    $scope.rishvi.editRoute = 'usersEdit';

                    adminRolesService.all().then(function (resp) {
                        $scope.roles = resp.data;
                    });

                }]
        });

}(window.angular));

