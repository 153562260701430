(function (angular) {
    "use strict";

    angular.module('myApp').component('shippingService', {
        templateUrl: '/shippingService/views/index.tpl.html',
        controller: 'shippingServiceController'
    });

    angular.module('myApp').controller('shippingServiceController',
        [
            '$scope', 'rishviList', "$state",'$rootScope',
            function ($scope, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/shipping-services/';
                $scope.list.module = 'shippingService';
                $scope.list.actions = {
                    "delete": "shippingService.delete"
                };

                $scope.isEditShippingServiceAccess = $rootScope.hasAccess("shippingService.edit");

                $scope.list.load();
                
                $scope.EditShippingService = function (id) {
                    $state.go("shippingServiceEdit", { id: id });
                }
            }
        ]);

}(window.angular));
