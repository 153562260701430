(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('openOrderPLService',
            [
                '$http',
                function ($http) {

                    var baseUrl = 'api/orders/';

                    this.list = function (filters) {
                        return $http({
                            url: baseUrl + 'get-open-order-pl',
                            params: filters,
                            method: 'GET'
                        });
                    };

                    this.exportToExcel = function (dto) {
                        return $http({
                            url: baseUrl + 'export-open-order-pl',
                            method: 'POST',
                            data: dto
                        });
                    };
                }
            ]);

}(window.angular));
