(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('404',
                {
                    url: '/admin/404',
                    templateUrl: '/errors/404.tpl.html'
                });
    }]);
    
})(angular);
