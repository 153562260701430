(function (angular) {
    "use strict";

    angular.module('myApp').component('resetPassword',
        {
            templateUrl: '/auth/views/reset-password.tpl.html',
            controller: 'ResetPasswordController'
        });

    angular.module('myApp').controller('ResetPasswordController',
        [
            '$scope', '$http', 'authService', '$stateParams', '$state', '$rootScope', function ($scope, $http, authService, $stateParams, $state, $rootScope) {

                if ($stateParams.token === undefined || $stateParams.token === '') {
                    $state.go("login");
                }

                $scope.dto = {};
                $scope.result = {};

                $scope.submit = function () {
                    $scope.result = {};

                    authService.resetPassword($stateParams.token, $scope.dto)
                        .then(function (resp) {

                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 4000
                            });

                            if (resp.data.success) {
                                $rootScope.result = resp.data;
                                $state.go("login");
                            }
                            $scope.result = resp.data;

                        });
                };
            }
        ]);

}(window.angular));
