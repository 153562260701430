(function (angular) {
    "use strict";

    angular.module('myApp').component('usersEdit',
        {
            templateUrl: '/users/views/userEdit.tpl.html',
            controller: ['$scope', '$state', 'userService', 'adminRolesService', 'rishviEdit',
                function ($scope, $state, userService, adminRolesService, rishviEdit) {

                    $scope.rishvi = new rishviEdit();
                    $scope.rishvi.service = userService;
                    $scope.rishvi.listRoute = 'users';
                    $scope.rishvi.load();

                    adminRolesService.all().then(function (resp) {
                        $scope.roles = resp.data;
                    });

                    $scope.deleteUser = function (id) {
                        
                        var ids = [id];
                        Swal.fire({
                            title: "Are you sure?",
                            text: "You want to delete User?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!"
                        }).then(function (result) {
                            if (result.isConfirmed) {
                                userService.delete(ids).then(function (resp) {
                                    var result = resp.data;

                                    Swal.fire({
                                        position: "top-right",
                                        icon: resp.data.messageType === 1 ? "success" : "error",
                                        title: resp.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                    if (result.success)
                                        $state.go('users');
                                });
                            };
                        });
                    }
                }]
        });

}(window.angular));
