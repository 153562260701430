(function (angular) {
    "use strict";

    angular.module('myApp').component("selectBoxOptGroup", {
        templateUrl: "/core/components/form/selectBoxOptGroup/select-box-opt-group.tpl.html",
        transclude: true,
        bindings: {
            name: '@',
            label: '@',
            required: '@',
            data: '=',
            service: '=',
            buttonclick: '&',
            isbuttonshow: '@?',
            buttonlabel: '@?',
            change: '&',
            isdisable: '@?',
            permissions: '@?',
            ishide: '@?',
            blankLabel: '@?'
        }
    });

}(window.angular));
