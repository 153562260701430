var localStoragePrefix = 'doodle';
var tokenName = 'jwta';

(function (angular) {
    'use strict';

    var app = angular.module("myApp", [
        "ui.bootstrap",
        "ui.router",
        "ngCookies",
        "ngSanitize",
        "angular-jwt",
        "ui.gravatar",
        "checklist-model",
        "LocalStorageModule",
        "blockUI",
        "ui.sortable",
        "slugifier",
        "monospaced.elastic",
        "ui.codemirror",
        "ui.mask",
        "ngDialog",
        "ngFileSaver",
        "bw.paging",
        "ui.tree",
        "ng-nestable",
        "xeditable",
        "ngPrint",
        "ngFileUpload",
    ]);

    app.config([
        '$urlRouterProvider',
        "$locationProvider",
        "jwtOptionsProvider",
        "$httpProvider",
        "localStorageServiceProvider",
        "$compileProvider",
        '$stateProvider',
        function ($urlRouterProvider,
            $locationProvider,
            jwtOptionsProvider,
            $httpProvider,
            localStorageServiceProvider,
            $compileProvider,
            $stateProvider) {

            // unsafe
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);

            /**
             *  Set prefix for local storage data
             */
            localStorageServiceProvider.setPrefix(localStoragePrefix);

            /**
             * Added authentication barrier token for all request
             * - Also skip sending auth token for html templates
             */
            jwtOptionsProvider.config({
                tokenGetter: function (options) {
                    // Skip authentication for any requests ending in .html
                    if (options.url.substr(options.url.length - 5) === '.html') {
                        return null;
                    }

                    return window.localStorage[localStoragePrefix + '.' + tokenName].slice(1, -1);
                }
            });
            $httpProvider.interceptors.push('jwtInterceptor');

            $httpProvider.interceptors.push([
                "$q", "$state", "localStorageService", "$rootScope", function ($q, $state, localStorageService, $rootScope) {
                    return {
                        'responseError': function (rejection) {

                            if (rejection.status === 400 ||
                                rejection.status === 401 ||
                                rejection.status === 419) {

                                localStorageService.remove(tokenName);
                                $rootScope.loggedIn = false;
                                $rootScope.auth = null;

                                auth.saveAttemptUrl();

                                $state.go("login");

                                return rejection;
                            }

                            return $q.reject(rejection);
                        }
                    };
                }
            ]);

            $urlRouterProvider.otherwise('/');

            $stateProvider.state('admin', {
                resolve: {
                    greeting: ['$rootScope', '$http', '$q', '$timeout',
                        function ($rootScope, $http, $q, $timeout) {
                            var deferred = $q.defer();
                            $timeout(function () {
                                $http.get('api/me/permissions').then(function (resp) {
                                    $rootScope.permissions = JSON.stringify(resp.data);
                                    deferred.resolve(resp.data);
                                });
                            }, 100);
                            deferred.resolve(null);
                            return deferred.promise;
                        }]
                }
            });

            //$stateProvider.state('site-tools', { parent: 'admin' });
            $stateProvider.state('system', { parent: 'admin' });
            $stateProvider.state('screen', { parent: 'admin' });
            $stateProvider.state('permission', { parent: 'system' });
            //$stateProvider.state('member', { parent: 'admin' });

            $locationProvider.html5Mode(true);
        }]);

    app.controller('BodyController', ['$rootScope', '$scope', function ($rootScope, $scope) {

        $scope.hasAccess = function (permissions) {
            return $rootScope.hasAccess(permissions);
        };
    }]);

    app.run([
        '$rootScope', '$state', '$transitions', 'auth', 'localStorageService', '$http', '$cookies', 'editableOptions', '$templateCache',
        function ($rootScope, $state, $transitions, auth, localStorageService, $http, $cookies, editableOptions, $templateCache) {

            $templateCache.put('angular-block-ui/angular-block-ui.ng.html',
                '<div class=\"block-ui-overlay\"></div><div class=\"block-ui-message-container\" aria-live=\"assertive\" aria-atomic=\"true\"><div class="loadernew"></div></div>');

            //<div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div></div>

            $rootScope.result = null;
            $rootScope.year = new Date().getFullYear();
            $rootScope.assetsAdminPath = assetsAdminPath;
            $rootScope.redirectToUrlAfterLogin = {};
            //$http.get('api/linnwork/auth').then(function (resp) {
            //    if (resp.data.success === false) {
            //        alert(resp.data.message);
            //    }
            //});
            editableOptions.theme = 'bs4';
            //return $state.target("home");

            $rootScope.hasAccess = function (permissions) {
                if (permissions !== undefined && $rootScope.permissions != null) {

                    var allow = false;

                    angular.forEach(permissions.split(','), function (value) {
                        if ($rootScope.permissions.indexOf(value) !== -1) {
                            allow = true;
                        }
                    });

                    return allow;
                }

                return true;
            };

            $rootScope.permissionsLoaded = function () {
                return $rootScope.permissions != null;
            };

            $transitions.onBefore({}, function (trans) {

                if (trans.$to().name === 'logout') {

                    localStorageService.remove(tokenName);
                    $cookies.remove('auth');
                    $rootScope.loggedIn = false;
                    $rootScope.auth = null;
                    setTimeout(function () {
                        location.reload();
                    }, 1000);
                    return $state.target("login");

                }

                if (!auth.loggedIn()) {
                    $rootScope.loggedIn = false;
                    //if (trans.$to().name !== "login") {
                    //    auth.saveAttemptUrl();
                    //    return $state.target("login");
                    //}
                    if (trans.$to().name === "home") {
                        auth.saveAttemptUrl();
                        return $state.target("login");
                    }
                    else if(trans.$to().data !== undefined) {
                        auth.saveAttemptUrl();
                        return $state.target("login");
                    }
                }
                else {

                    $rootScope.loggedIn = true;
                    $cookies.put('auth', localStorageService.get(tokenName));

                    var permissions = null;

                    if (trans.$to().data !== undefined)
                        permissions = trans.$to().data.permissions;

                    if (permissions !== null) {

                        var allow = false;

                        if ($rootScope.permissions == null) {
                            $http.get('api/me/permissions').then(function (resp) {
                                $rootScope.permissions = JSON.stringify(resp.data);
                                angular.forEach(permissions, function (value) {
                                    if ($rootScope.permissions.indexOf(value) !== -1) {
                                        allow = true;
                                    }
                                });

                                if (allow == false) {
                                    // TODO: Permission failed redirect access denied
                                    return $state.go("home");
                                }
                            });
                        }
                        else {

                            angular.forEach(permissions, function (value) {
                                if ($rootScope.permissions.indexOf(value) !== -1) {
                                    allow = true;
                                }
                            });

                            if (allow == false) {
                                // TODO: Permission failed redirect access denied
                                return $state.target("home");
                            }
                        }
                    }
                    // TODO: Permission check
                }
            });
        }
    ]);

    app.filter('exclude', function () {
        return function (list, currentModel, selected) {
            var selectedLength = selected.length;
            var out = [];
            angular.forEach(list, function (listItem) {
                var add = true;
                for (var index = 0; index < selectedLength; index++) {
                    if (selected[index].mapWith !== currentModel && selected[index].mapWith === listItem.id) {
                        add = false;
                        break;
                    }
                }

                if (add) {
                    out.push(listItem);
                }

            });

            return out;
        }
    });

    app.directive('capitalize', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                var capitalize = function (inputValue) {
                    if (inputValue == undefined) inputValue = '';
                    var capitalized = inputValue.toUpperCase();
                    if (capitalized !== inputValue) {
                        // see where the cursor is before the update so that we can set it back
                        var selection = element[0].selectionStart;
                        modelCtrl.$setViewValue(capitalized);
                        modelCtrl.$render();
                        // set back the cursor after rendering
                        element[0].selectionStart = selection;
                        element[0].selectionEnd = selection;
                    }
                    return capitalized;
                }
                modelCtrl.$parsers.push(capitalize);
                capitalize(scope[attrs.ngModel]); // capitalize initial value
            }
        };
    });

    app.filter('capitalizef', function () {
        return function (input) {
            return (angular.isString(input) && input.length > 0) ? input.charAt(0).toLowerCase() + input.substr(1) : input;
        }
    });

    app.factory('auth',
        [
            '$http', 'jwtHelper', 'localStorageService', '$rootScope', '$cookies', '$location',
            function ($http, jwtHelper, localStorageService, $rootScope, $cookies, $location) {
                return {
                    loggedIn: function () {

                        var token = localStorageService.get(tokenName);

                        if (token == null)
                            return false;

                        if (jwtHelper.isTokenExpired(token)) {

                            localStorageService.remove(tokenName);
                            return false;
                        } else {

                            $rootScope.auth = jwtHelper.decodeToken(token);
                        }

                        //CKFinder.config.connectorInfo = 'token=' + token;
                        $cookies.put('auth', token);

                        return true;
                    },
                    saveAttemptUrl: function () {
                        if ($location.path().toLowerCase() !== '/auth/login') {
                            $rootScope.redirectToUrlAfterLogin.url = $location.path();
                        }
                        else {
                            $rootScope.redirectToUrlAfterLogin.url = 'home';
                        }
                    },
                    redirectToAttemptedUrl: function () {
                        if ($rootScope.redirectToUrlAfterLogin.url === undefined)
                            $location.path("home");
                        else
                            $location.path($rootScope.redirectToUrlAfterLogin.url);
                    }
                };
            }
        ]);

})(angular);

Object.size = function (obj) {
    var size = 0, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }

    return size;
};

function unflatten(arr) {
    
    var tree = [],
        mappedArr = {},
        arrElem,
        mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var i = 0, len = arr.length; i < len; i++) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id].nodes = [];
    }
    for (var id in mappedArr) {

        if (mappedArr.hasOwnProperty(id)) {
            mappedElem = mappedArr[id];

            // If the element is not at the root level, add it to its parent array of children.
            if (mappedElem.parentId != "00000000-0000-0000-0000-000000000000") {
                mappedArr[mappedElem.parentId].nodes.push(mappedElem);
            }

            // If the element is at the root level, add it to first level elements array.
            else {
                tree.push(mappedElem);
            }
        }
    }
    return tree;
}

//Google map code
var autocomplete;
var scope = null;
function initAutocomplete(intializeId) {

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(intializeId), { types: ['geocode'], placeholder: undefined });
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace();

    scope.rishvi.dto.address = place.formatted_address;
    scope.rishvi.dto.latitude = place.geometry.location.lat();
    scope.rishvi.dto.longitude = place.geometry.location.lng();
}
//end of Google map code
