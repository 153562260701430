(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('users', {
                parent: 'screen',
                url: '/users',
                component: 'users',
                data: {
                    permissions: ['adminUsers']
                }
            })
            .state('usersCreate', {
                parent: 'screen',
                url: '/users/create',
                component: 'usersCreate',
                data: {
                    permissions: ['adminUsers.create']
                }
            })
            .state('usersEdit', {
                parent: 'screen',
                url: '/users/edit/{id}',
                component: 'usersEdit',
                data: {
                    permissions: ['adminUsers.edit']
                }
            });
        }
    ]);

})(angular);
