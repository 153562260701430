(function (angular) {
    "use strict";

    angular.module('myApp').component('calculatorCreate',
        {
            templateUrl: '/calculator/views/calculatorCreate.tpl.html',
            controller: ['$scope', '$state', 'rishviCreate', 'calculatorService', 'inputCurrencyService', 'outputCurrencyService', 'shippingServiceService', 'userService', 'salesAnalyticsService', 'categoryDutyService', '$filter',
                function ($scope, $state, rishviCreate, calculatorService, inputCurrencyService, outputCurrencyService, shippingServiceService, userService, salesAnalyticsService, categoryDutyService, $filter) {

                    $scope.rishvi = new rishviCreate();
                    $scope.rishvi.dto.originalDuty = 0;
                    $scope.rishvi.dto.nativeCost = 0;
                    $scope.rishvi.dto.costDutyFreight = 0;
                    $scope.rishvi.dto.shippingExVat = 0;
                    $scope.rishvi.dto.marketPlaceFee = 0;
                    $scope.rishvi.dto.sellingPriceIncVat = 0;

                    $scope.rishvi.dto.duty = 0;
                    $scope.rishvi.dto.gbpCost = 0;
                    $scope.rishvi.dto.freight = 0;

                    $scope.rishvi.dto.profitOverride = 0;
                    $scope.rishvi.dto.margin = 0;
                    $scope.rishvi.dto.vatAdjustment = 0;
                    $scope.rishvi.dto.overHeads = 0;

                    $scope.allCalculators = [];

                    inputCurrencyService.all().then(function (resp) {
                        $scope.allInputCurrency = resp.data;
                    });

                    outputCurrencyService.all().then(function (resp) {
                        $scope.allOutputCurrency = resp.data;
                    });

                    shippingServiceService.all().then(function (resp) {
                        $scope.allShippingServices = resp.data;
                    });

                    userService.userHigherLevelUsers().then(function (resp) {
                        $scope.allApprovalUsers = resp.data;
                    });

                    $scope.shippingServiceInlineChange = function (item) {
                        shippingServiceService.getShippingServiceCost(item.shippingServiceId).then(function (resp) {
                            item.shippingExVat = resp.data;

                            ProfitOverrideInlineCalc(item);
                        });
                    };

                    $scope.shippingServiceInlineChangePopup = function (item) {
                        shippingServiceService.getShippingServiceCost(item.shippingServiceId).then(function (resp) {
                            item.shippingExVat = resp.data;
                        });
                    };

                    //$scope.marketPlaceChange = function () {
                    //    marketPlaceFeeCalc();

                    //    vatAdjustmentCalc();
                    //}

                    $scope.marketPlaces = [
                        {
                            "id": "Amazon Seller",
                            "name": "Amazon Seller"
                        },
                        {
                            "id": "eBay",
                            "name": "eBay"
                        },
                        {
                            "id": "Magento",
                            "name": "Magento"
                        },
                        {
                            "id": "Groupon",
                            "name": "Groupon"
                        },
                        {
                            "id": "Amazon Vendor",
                            "name": "Amazon Vendor"
                        },
                        {
                            "id": "Fruugo",
                            "name": "Fruugo"
                        }
                    ];

                    $scope.yesNoStatuses = [
                        {
                            "id": "Yes",
                            "name": "Yes"
                        },
                        {
                            "id": "No",
                            "name": "No"
                        }
                    ];

                    $scope.openNewCalculator = function () {
                        // Call first validators
                       
                        $scope.rishvi.dto.approvalUserId = $scope.rishvi.dto.approvalUserId === "" ? undefined : $scope.rishvi.dto.approvalUserId;
                        calculatorService.validateNewCalculator($scope.rishvi.dto).then(function (resp) {
                            if (resp.data.success) {
                                $scope.rishvi.result = resp.data;
                                $scope.addedSkuArray = [];
                                $scope.rishvi.dto.findSku = '';
                                $scope.searchResult = [];
                                $('#addCalculatorModal').modal('show');
                            }
                            else {
                                $scope.rishvi.result = resp.data;
                            }
                        });
                    };

                    $scope.closeCalculator = function () {

                        $scope.rishvi.dto.findSku = '';
                        $scope.searchResult = [];

                        $("#addCalculatorModal").modal('hide');
                    };

                    $scope.addNewCalculator = function () {

                        $scope.addedSkuArray.filter(function (el) {
                            
                            var stockItemId = el.stockItemId;
                            $scope.checkAlreadyExist = $filter('filter')($scope.allCalculators, { 'stockItemId': stockItemId });

                            if ($scope.checkAlreadyExist.length === 0) {

                                if ($scope.allCalculators.length === 0) {
                                    $scope.rishvi.dto.id = 1;
                                }
                                else {
                                    var lastRecord = $scope.allCalculators[$scope.allCalculators.length - 1];
                                    $scope.rishvi.dto.id = lastRecord.id + 1;
                                }
                                $scope.rishvi.dto.searchSku = el.searchSku;
                                $scope.rishvi.dto.categoryId = el.categoryId
                                $scope.rishvi.dto.stockItemId = el.stockItemId;
                                $scope.rishvi.dto.duty = el.duty;
                                $scope.rishvi.dto.shippingServiceId = el.shippingServiceId;
                                $scope.rishvi.dto.nativeCost = el.nativeCost;
                                $scope.rishvi.dto.gbpCost = 0;
                                $scope.rishvi.dto.costDutyFreight = 0;
                                $scope.rishvi.dto.shippingExVat = el.shippingExVat;
                                $scope.rishvi.dto.vatApplicable = el.vatApplicable;
                                $scope.rishvi.dto.marketPlaceFee = 0;
                                $scope.rishvi.dto.vatAdjustment = 0;
                                $scope.rishvi.dto.sellingPriceIncVat = el.sellingPriceIncVat;
                                $scope.rishvi.dto.profitOverride = 0;
                                $scope.rishvi.dto.margin = 0;
                                $scope.rishvi.dto.overHeads = el.overHeads;

                                $scope.rishvi.dto.originalDuty = parseFloat(el.duty) / 100;
                                $scope.rishvi.dto.shippingServiceIdText = $("[name=shippingServiceInlineIdPopup] option[value='string:" + el.shippingServiceId + "']:first").text();
                                $scope.nativeCostInlineChange($scope.rishvi.dto);
                                //$scope.shippingServiceInlineChange($scope.rishvi.dto)
                                //shippingServiceService.getShippingServiceCost($scope.rishvi.dto.shippingServiceId).then(function (resp) {
                                  //  $scope.rishvi.dto.shippingExVat = resp.data;

                                    ProfitOverrideInlineCalc($scope.rishvi.dto);
                                    $scope.vatApplicableInlineChange($scope.rishvi.dto)
                                    $scope.sellingPriceInlineChange($scope.rishvi.dto)
                                    $scope.overHeadInlineChange($scope.rishvi.dto);

                                    var CalcObject = {};
                                    CalcObject = angular.copy($scope.rishvi.dto);

                                    $scope.allCalculators.push(CalcObject);
                                //});
                                

                                
                            }
                        });

                        $("#addCalculatorModal").modal('hide');
                        $scope.addedSkuArray = [];
                        $scope.rishvi.dto.findSku = '';
                        $scope.searchResult = [];

                    };

                    $scope.saveAllCalculators = function () {
                        // Create API CALL
                        if ($scope.allCalculators.length === 0) {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Please add atleast 1 calculator!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }
                        else {
                            calculatorService.createNewCalculators($scope.allCalculators).then(function (resp) {

                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                if (!resp.data.success) {
                                    $scope.rishvi.result = resp.data;
                                }
                                else {
                                    $state.go("calculatorView");
                                }
                            });
                        }
                    };

                    $scope.gbpCost = 0;
                    $scope.nativeCostInlineChange = function (item) {

                        $scope.gbpCost = 0;
                        var inputCurrency = parseFloat(item.inputCurrency.split(':')[1]);

                        var nativeCost = parseFloat(item.nativeCost);
                        $scope.gbpCost = inputCurrency * nativeCost;

                        item.gbpCost = $scope.gbpCost.toFixed(2);

                        var freightPercentage = parseFloat(item.freightPercentage);
                        item.freight = ((freightPercentage / 100) * $scope.gbpCost).toFixed(2);

                        var newDuty = $scope.gbpCost * parseFloat(item.originalDuty);
                        item.duty = newDuty === 0 ? item.originalDuty : newDuty.toFixed(2);

                        var duty = parseFloat(item.duty);
                        var freight = parseFloat(item.freight);

                        var costDutyFreight = $scope.gbpCost + duty + freight;
                        item.costDutyFreight = costDutyFreight.toFixed(2);

                        ProfitOverrideInlineCalc(item);
                    };

                    $scope.sellingPriceInlineChange = function (item) {

                        marketPlaceFeeInlineCalc(item);
                    };

                    function marketPlaceFeeInlineCalc(item) {

                        var marketPlace = item.marketPlace;
                        var sellingPriceIncVat = parseFloat(item.sellingPriceIncVat);
                        if (marketPlace === "Amazon Seller") {
                            item.marketPlaceFee = (sellingPriceIncVat * 0.15).toFixed(2);
                        }
                        else if (marketPlace === "eBay") {
                            item.marketPlaceFee = ((sellingPriceIncVat * 0.11) + (sellingPriceIncVat * 0.05) + 0.05).toFixed(2);
                        }
                        else if (marketPlace === "Magento") {
                            item.marketPlaceFee = (sellingPriceIncVat * 0.015).toFixed(2);
                        }
                        else if (marketPlace === "Groupon") {
                            item.marketPlaceFee = (sellingPriceIncVat * 0.04).toFixed(2);
                        }
                        else if (marketPlace === "Amazon Vendor") {
                            item.marketPlaceFee = (sellingPriceIncVat * 0.125).toFixed(2);
                        }
                        else if (marketPlace === "Fruugo") {
                            item.marketPlaceFee = (sellingPriceIncVat * 0.20).toFixed(2);
                        }

                        vatAdjustmentInlineCalc(item);
                    }

                    function vatAdjustmentInlineCalc(item) {

                        var marketPlace = item.marketPlace;
                        var vatApplicable = item.vatApplicable;
                        if (vatApplicable === "No") {
                            item.vatAdjustment = 0;
                        }
                        else {
                            if (marketPlace === "Amazon Vendor") {
                                item.vatAdjustment = 0;
                            }
                            else if (marketPlace === "Groupon") {
                                item.vatAdjustment = 0;
                            }
                            else {
                                var sellingPriceIncVat = parseFloat(item.sellingPriceIncVat);
                                var vatAdjustment = sellingPriceIncVat - (sellingPriceIncVat / 1.2);
                                item.vatAdjustment = vatAdjustment.toFixed(2);
                            }
                        }

                        ProfitOverrideInlineCalc(item);
                    }

                    $scope.vatApplicableInlineChange = function (item) {

                        vatAdjustmentInlineCalc(item);

                        ProfitOverrideInlineCalc(item);
                    }

                    function ProfitOverrideInlineCalc(item) {

                        var sellingPriceIncVat = parseFloat(item.sellingPriceIncVat);
                        var costDutyFreight = parseFloat(item.costDutyFreight);
                        var shippingExVat = parseFloat(item.shippingExVat);

                        var marketPlaceFee = parseFloat(item.marketPlaceFee);
                        var vatAdjustment = parseFloat(item.vatAdjustment);

                        var overHeads = parseFloat(item.overHeads);

                        var outputCurrency = parseFloat(item.outputCurrency.split(':')[1]);

                        var calc1 = (sellingPriceIncVat * outputCurrency);

                        var childCalc = (marketPlaceFee + vatAdjustment) * outputCurrency;

                        var calc2 = costDutyFreight + shippingExVat + childCalc + overHeads;

                        var profitOverride = calc1 - calc2;

                        item.profitOverride = profitOverride.toFixed(2);

                        var newProfitOverride = parseFloat(profitOverride);
                        var margin = newProfitOverride / sellingPriceIncVat;

                        item.margin = margin.toFixed(2);
                    };

                    $scope.overHeadInlineChange = function (item) {
                        ProfitOverrideInlineCalc(item);
                    };

                    //$scope.outputCurrencyChange = function () {
                    //    ProfitOverrideCalc();
                    //};

                    $scope.EditCalculator = function (id) {
                        
                        $scope.editDto = $filter('filter')($scope.allCalculators, { 'id': id });
                        
                        $scope.selected = angular.copy($scope.editDto[0]);
                    };

                    $scope.DeleteCalculator = function (id) {
                        $scope.allCalculators.filter(function (el) {
                            if (el.id === id) {
                                var index = $scope.allCalculators.indexOf(el);
                                $scope.allCalculators.splice(index, 1);
                            }
                        });
                    };

                    // Fetch data
                    $scope.fetchSkus = function () {

                        var searchText_len = $scope.rishvi.dto.findSku.trim().length;

                        $scope.searchSkuFilterDto = {};
                        $scope.searchSkuFilterDto.searchSku = $scope.rishvi.dto.findSku.trim();
                        // Check search text length
                        if (searchText_len > 2) {
                            salesAnalyticsService.getAllStockItems($scope.searchSkuFilterDto).then(function successCallback(response) {
                                $scope.searchResult = response.data;
                                setTimeout(function () {
                                    $("#searchSku").focus();
                                }, 500);

                            });
                        } else {
                            $scope.searchResult = [];
                        }
                    }

                    $scope.fetchInlineSkus = function (sku) {

                        var searchText_len = sku.trim().length;

                        $scope.searchSkuFilterDto = {};
                        $scope.searchSkuFilterDto.searchSku = sku.trim();
                        // Check search text length
                        if (searchText_len > 2) {
                            salesAnalyticsService.getAllStockItems($scope.searchSkuFilterDto).then(function successCallback(response) {
                                $scope.searchInlineResult = response.data;
                                setTimeout(function () {
                                    $("#searchInlineSku").focus();
                                }, 500);

                            });
                        } else {
                            $scope.searchInlineResult = [];
                        }
                    }

                    $scope.addedSkuArray = [];
                    $scope.setSkuValue = function (index, $event) {
                        
                        $scope.addedSku = {};
                        $scope.addedSku.searchSku = $scope.searchResult[index].name;
                        $scope.addedSku.stockItemId = $scope.searchResult[index].id;
                        $scope.addedSku.categoryId = $scope.searchResult[index].other.split(':')[0];
                        $scope.addedSku.duty = $scope.searchResult[index].other.split(':')[1];
                        $scope.addedSku.nativeCost = 0;
                        $scope.addedSku.shippingServiceId = "";
                        $scope.addedSku.vatApplicable = "";
                        $scope.addedSku.sellingPriceIncVat = 0;
                        $scope.addedSku.overHeads = 0;
                        var supplierId = $scope.searchResult[index].other2;

                        $scope.checkAddedSku = $filter('filter')($scope.addedSkuArray, { 'stockItemId': $scope.addedSku.stockItemId });

                        var checkbox = $event.target;
                        if (checkbox.checked) {
                            if ($scope.checkAddedSku.length === 0) {
                               
                                if (supplierId === "") {
                                    $scope.addedSku.nativeCost = 0;
                                    $scope.addedSkuArray.push($scope.addedSku);
                                }
                                else {
                                     // make api call and get native cost
                                    $scope.nativeCostFilter = {};
                                    $scope.nativeCostFilter.supplierId = supplierId;
                                    $scope.nativeCostFilter.stockItemId = $scope.addedSku.stockItemId;

                                    salesAnalyticsService.getSupplierNativeCost($scope.nativeCostFilter).then(function successCallback(response) {
                                        $scope.addedSku.nativeCost = response.data.data; 
                                        $scope.addedSkuArray.push($scope.addedSku);
                                    });
                                }                                
                            }
                        }
                        else {
                            if ($scope.checkAddedSku.length > 0) {
                                var indexRemove = $scope.addedSkuArray.indexOf($scope.checkAddedSku[0]);
                                $scope.addedSkuArray.splice(indexRemove, 1);
                            }
                        }

                        $event.stopPropagation();
                    }

                    $scope.isSelected = function (id) {
                        if ($filter('filter')($scope.addedSkuArray, { 'stockItemId': id }).length > 0) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    };

                    $scope.DeleteAddedCalculator = function (stockItemId) {
                        $scope.checkAddedSku = $filter('filter')($scope.addedSkuArray, { 'stockItemId': stockItemId });

                        var indexRemove = $scope.addedSkuArray.indexOf($scope.checkAddedSku[0]);
                        $scope.addedSkuArray.splice(indexRemove, 1);
                    }

                    $scope.setInlineValue = function (item, index, $event) {
                        
                        item.searchSku = $scope.searchInlineResult[index].name;
                        item.stockItemId = $scope.searchInlineResult[index].id;
                        item.categoryId = $scope.searchInlineResult[index].other.split(':')[0];
                        item.duty = $scope.searchInlineResult[index].other.split(':')[1];
                        var supplierId = $scope.searchInlineResult[index].other2;

                        if (supplierId === "") {
                            item.nativeCost = 0;

                            $scope.searchInlineResult = [];

                            $scope.gbpCost = 0;
                            var inputCurrency = parseFloat(item.inputCurrency.split(':')[1]);

                            $scope.gbpCost = inputCurrency * parseFloat(item.nativeCost);

                            item.gbpCost = $scope.gbpCost.toFixed(2);

                            var freightPercentage = parseFloat(item.freightPercentage);
                            var gbpCost = parseFloat(item.gbpCost);

                            item.freight = ((freightPercentage / 100) * gbpCost).toFixed(2);

                            if (item.searchSku === "") {
                                item.duty = 0;

                                var duty = parseFloat(item.duty);
                                var freight = parseFloat(item.freight);
                                var costDutyFreight = gbpCost + duty + freight;
                                item.costDutyFreight = costDutyFreight.toFixed(2);
                                ProfitOverrideInlineCalc(item);
                            }
                            else {
                                categoryDutyService.getCategoryDuty(item.categoryId).then(function (resp) {
                                    item.originalDuty = parseFloat(resp.data) / 100;

                                    var newDuty = parseFloat(item.gbpCost) * parseFloat(item.originalDuty);
                                    item.duty = newDuty === 0 ? item.originalDuty : newDuty.toFixed(2);

                                    var duty = parseFloat(item.duty);
                                    var freight = parseFloat(item.freight);
                                    var costDutyFreight = gbpCost + duty + freight;
                                    item.costDutyFreight = costDutyFreight.toFixed(2);
                                    ProfitOverrideInlineCalc(item);
                                });
                            }
                            $event.stopPropagation();
                        }
                        else {
                            // make api call and get native cost
                            $scope.nativeCostFilter = {};
                            $scope.nativeCostFilter.supplierId = supplierId;
                            $scope.nativeCostFilter.stockItemId = item.stockItemId;

                            salesAnalyticsService.getSupplierNativeCost($scope.nativeCostFilter).then(function successCallback(response) {
                                item.nativeCost = response.data.data;

                                $scope.searchInlineResult = [];

                                $scope.gbpCost = 0;
                                var inputCurrency = parseFloat(item.inputCurrency.split(':')[1]);

                                $scope.gbpCost = inputCurrency * parseFloat(item.nativeCost);

                                item.gbpCost = $scope.gbpCost.toFixed(2);

                                var freightPercentage = parseFloat(item.freightPercentage);
                                var gbpCost = parseFloat(item.gbpCost);

                                item.freight = ((freightPercentage / 100) * gbpCost).toFixed(2);

                                if (item.searchSku === "") {
                                    item.duty = 0;

                                    var duty = parseFloat(item.duty);
                                    var freight = parseFloat(item.freight);
                                    var costDutyFreight = gbpCost + duty + freight;
                                    item.costDutyFreight = costDutyFreight.toFixed(2);
                                    ProfitOverrideInlineCalc(item);
                                }
                                else {
                                    categoryDutyService.getCategoryDuty(item.categoryId).then(function (resp) {
                                        item.originalDuty = parseFloat(resp.data) / 100;

                                        var newDuty = parseFloat(item.gbpCost) * parseFloat(item.originalDuty);
                                        item.duty = newDuty === 0 ? item.originalDuty : newDuty.toFixed(2);

                                        var duty = parseFloat(item.duty);
                                        var freight = parseFloat(item.freight);
                                        var costDutyFreight = gbpCost + duty + freight;
                                        item.costDutyFreight = costDutyFreight.toFixed(2);
                                        ProfitOverrideInlineCalc(item);
                                    });
                                }
                                $event.stopPropagation();
                            });
                        }
                    }

                    $scope.hideResult = function (e) {
                        //$window.onclick = function (e) {
                            var target = $(e.target);
                            if (!target) return;
                            if (!target.hasClass('searchResult') && !target.is($('.searchResult').children())) {
                                $(".searchResult").hide();
                            }
                        //};
                    }
                       
                    $scope.searchboxClicked = function ($event) {
                        $event.stopPropagation();
                        $(".searchResult").show();
                    }

                    $scope.clearSkuSearch = function () {
                        $scope.rishvi.dto.findSku = '';
                        $scope.searchResult = [];
                    };

                    $scope.containerClicked = function () {

                        //$scope.searchResult = [];
                    }

                    $scope.containerInlineClicked = function () {
                        $scope.searchInlineResult = [];
                    }

                    $scope.selected = {};
                    $scope.getTemplate = function (id) {
                        
                        if (id === $scope.selected.id) {
                            return 'edit';
                        }
                        else return 'display';
                    };

                    $scope.ResetCalculator = function (index) {
                        $scope.originalObject = angular.copy($scope.selected);

                        $scope.allCalculators[index] = $scope.originalObject;

                        $scope.selected = {};
                    };

                    $scope.UpdateCalculator = function (item) {
                        // call create validators
                        if (item.margin === "-Infinity") {
                            item.margin = 0;
                        }
                        calculatorService.addNewCalculatorArray(item).then(function (resp) {
                            if (resp.data.success) {
                                item.shippingServiceIdText = $("#shippingServiceInlineId option:selected").text();
                                $scope.selected = {};
                            }
                            else {

                                Swal.fire({
                                    position: "top-right",
                                    icon: "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    };

                }]
        });

}(window.angular));
