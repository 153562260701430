(function (angular) {
    "use strict";

    angular.module('myApp').component('adminRoles',
        {
            templateUrl: '/adminRoles/views/index.tpl.html',
            controller: 'AdminRolesController'
        });

    angular.module('myApp').controller('AdminRolesController',
        [
            '$scope', '$http', 'adminRolesService', 'rishviList', "$state", '$rootScope',
            function ($scope, $http, adminRolesService, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/admin-roles/';
                $scope.list.module = 'adminRoles';
                $scope.list.actions = {
                    "delete": "adminRoles.delete"
                };
                $scope.isEditRolesAccess = $rootScope.hasAccess("adminRoles.edit");

                $scope.list.load();

                $scope.EditRole = function (id) {
                    $state.go("adminRolesEdit", { id: id });
                }

                //$scope.roles = {};
                //adminRolesService.all().then(function (resp) {
                //    $scope.roles = resp.data;
                //});
            }
        ]);

}(window.angular));
