(function (angular) {
    "use strict";

    angular.module('myApp').component('forgotPassword',
        {
            templateUrl: '/auth/views/forgot-password.tpl.html',
            controller: 'ForgotPasswordController'
        });

    angular.module('myApp').controller('ForgotPasswordController',
        [
            '$scope', '$http', 'authService', function ($scope, $http, authService) {

                $scope.dto = {};
                $scope.result = {};

                $scope.submit = function () {
                    authService.forgotPassword($scope.dto)
                        .then(function (resp) {

                            $scope.result = resp.data;

                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 4000
                            });

                            if (resp.data.success) {
                                $scope.dto = {};
                            }
                        });
                };
            }
        ]);

}(window.angular));
