(function (angular) {
  "use strict";

  angular.module('myApp').component('adminPermissionsCreate', {
    templateUrl: '/adminPermissions/views/create.tpl.html',
    controller: 'adminPermissionsCreateController'
  });

  angular.module('myApp').controller('adminPermissionsCreateController',
    [
      '$scope', 'adminPermissionsService', 'rishviCreate',
      function ($scope, adminPermissionsService, rishviCreate) {

        $scope.rishvi = new rishviCreate();
        $scope.rishvi.service = adminPermissionsService;

        $scope.rishvi.listRoute = 'adminPermissions';
        $scope.rishvi.editRoute = 'adminPermissionEdit';
        $scope.rishvi.dto.isParentSelected = "false";

        adminPermissionsService.all().then(function (resp) {
          $scope.permissionsData = resp.data;
          $('#parentId').attr('disabled', true);
        });

        $scope.changeParentSelected = function (isParentSelected) {

          if (isParentSelected === "true") {
            $scope.rishvi.dto.parentId = null;
            $('#parentId').removeAttr('disabled');
          } else {
            $scope.rishvi.dto.parentId = null;
            $('#parentId').attr('disabled', true);
          }
        };
      }
    ]);

}(window.angular));
