(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('ordersService', [
            '$http',
            function ($http) {

                var baseUrl = 'api/orders/';

                this.byId = function (id) {
                    return $http({
                        url: baseUrl + id,
                        method: 'GET'
                    });
                };

                this.getStockItems = function (dto) {
                    return $http({
                        url: baseUrl + 'get-stock-items',
                        method: 'POST',
                        data: dto
                    });
                };

                this.getAllCountry = function () {
                    return $http({
                        url: baseUrl + 'get-all-country',
                        method: 'GET'
                    });
                };

                this.getpostalServices = function () {
                    return $http({
                        url: baseUrl + 'get-postal-services',
                        method: 'GET'
                    });
                };

                this.addOrderItem = function (dto) {
                    return $http({
                        url: baseUrl + 'add-order-item',
                        method: 'POST',
                        data: dto
                    });
                };

                this.getOrderPackagingCalculation = function (id) {
                    return $http({
                        url: baseUrl + "get-order-packaging-calculation/" + id,
                        method: 'GET'
                    });
                };

                this.orderEdit = function (dto) {
                    return $http({
                        url: baseUrl + 'order-edit',
                        method: 'POST',
                        data: dto
                    });
                };

                this.setOrderPackaging = function (dto) {
                    return $http({
                        url: baseUrl + 'set-order-packaging',
                        method: 'POST',
                        data: dto
                    });
                };

                this.export = function (filters) {
                    $http({ url: baseUrl + 'export', params: filters, method: 'GET' })
                        .then(function (resp) {
                            var data = new Blob([resp.data], { type: 'text/csv;charset=utf-8' });
                            FileSaver.saveAs(data, 'sales-analytics.csv');
                        });
                };

                this.exportToExcel = function (dto) {
                    return $http({
                        url: baseUrl + 'export-order',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
