(function (angular) {
    "use strict";

    angular.module('myApp').component('profitLoss',
        {
            templateUrl: '/profitLoss/views/profitLoss.tpl.html',
            controller: ['$scope', '$state', 'profitLossService',
                function ($scope, $state, profitLossService) {

                }]
        });

}(window.angular));
