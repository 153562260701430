(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('categoryDuty', {
          parent: 'screen',
          url: '/category-duty',
          component: 'categoryDuty',
          data: {
              permissions: ['categoryDuty']
          }
        })
        .state('categoryDutyCreate', {
          parent: 'screen',
          url: '/category-duty/create',
          component: 'categoryDutyCreate',
          data: {
            permissions: ['categoryDuty.create']
          }
        })
        .state('categoryDutyEdit', {
          parent: 'screen',
          url: '/category-duty/edit/{id}',
          component: 'categoryDutyEdit',
          data: {
            permissions: ['categoryDuty.edit']
          }
        });
    }
  ]);

})(angular);
