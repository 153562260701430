(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('purchaseOrderService', [
            '$http',
            function ($http) {

                var baseUrl = 'api/purchase-orders/'
                this.exportToExcel = function (dto) {
                    debugger;
                    return $http({
                        url: baseUrl + 'export-purchaseorder',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
