(function (angular) {
    "use strict";

    angular.module('myApp').component('openOrderPL', {
        templateUrl: '/openOrderPL/views/index.tpl.html',
        controller: 'openOrderPLController'
    });

    angular.module('myApp').controller('openOrderPLController',
        [
            '$scope', 'rishviList', "$state", '$rootScope', 'openOrderPLService', '$filter',
            function ($scope, rishviList, $state, $rootScope, openOrderPLService, $filter) {

                $scope.openOrderPLFilterDto = {};

                //$scope.list = new rishviList();
                //$scope.list.url = 'api/orders/get-open-order-pl';
                //$scope.list.module = 'orders';
                //$scope.list.filters.additionalFilter = "";
                //$scope.list.filters.filterName = "";
                //$scope.list.filters.size = 10000;

                $scope.openOrderPLFilterDto = {};
                $scope.openOrderPLFilterDto.additionalFilter = "";
                $scope.openOrderPLFilterDto.filterName = "";
                $scope.openOrderPLFilterDto.page = 1;
                $scope.openOrderPLFilterDto.size = 10000;
                
                $scope.load = function () {
                    if ($.fn.DataTable.isDataTable('#kt_datatable')) {
                        $('#kt_datatable').DataTable().clear().destroy();
                    }

                    openOrderPLService.list($scope.openOrderPLFilterDto).then(function (resp) {
                        debugger;
                        $scope.openOrderPL = resp.data;
                        BindDatable();
                    });
                }

                $scope.load();

                function BindDatable() {
                    setTimeout(function () {

                        var table = $('#kt_datatable');

                        table.DataTable({
                            pageLength: 10,
                            scrollY: '70vh',
                            scrollX: true,
                            scrollCollapse: true,
                            bProcessing: true,
                            destroy: true,
                            //bSort: true,
                            order: [[7, "asc"]],
                            columnDefs: [{
                                "targets": 'no-sort',
                                "orderable": false
                            }],
                            buttons: [
                                {
                                    extend: 'excel',
                                    filename: 'OpenOrderPL',
                                    title: '',
                                    exportOptions: {
                                        columns: [0, 1, 2, 3, 4, 6, 7, 8, 9]
                                    }
                                },
                                {
                                    extend: 'csv',
                                    filename: 'OpenOrderPL',
                                    title: '',
                                    exportOptions: {
                                        columns: [0, 1, 2, 3, 4, 6, 7, 8, 9]
                                    }
                                }
                            ]
                        });

                    }, 500);
                };

                $scope.exportToExcel = function () {
                    $("#kt_datatable").DataTable().button('.buttons-excel').trigger();
                }

                $scope.exportToCsv = function () {
                    $("#kt_datatable").DataTable().button('.buttons-csv').trigger();
                }

                //$scope.exportToExcel = function () {
                //    $scope.filterData = {};
                //    $scope.filterData.filterName = $scope.openOrderPLFilterDto.filterName;
                //    $scope.filterData.value = '';
                //    openOrderPLService.exportToExcel($scope.filterData).then(function (resp) {

                //        if (resp.data.success) {
                //            var win = window.open(resp.data.data, '_blank');
                //            if (win) {
                //                //Browser has allowed it to be opened
                //                win.focus();
                //            } else {
                //                //Browser has blocked it
                //                alert('Please allow popups for this website');
                //            }
                //        }
                //        else {
                //            Swal.fire({
                //                position: "top-right",
                //                icon: resp.data.messageType === 1 ? "success" : "error",
                //                title: resp.data.message,
                //                showConfirmButton: false,
                //                timer: 2000
                //            });
                //        }
                //    });
                //};

                //$scope.searchOpenOrder = function () {
                //    $scope.list.load();
                //};

                //$scope.pageSizeChanged = function (size) {
                //    localStorageService.set('admin.page.size', size);
                //};

                $scope.filterOptionChange = function () {
                    if ($scope.openOrderPLFilterDto.filterName === "") {
                        $scope.openOrderPLFilterDto.additionalFilter = "";
                    }
                }

                $scope.searchOpenOrderPL = function () {
                    //$scope.list.load(true);
                    $scope.load();
                }

                $scope.viewMoreOrderItems = function (numOrderId) {

                    $scope.viewOrder = $filter('filter')($scope.openOrderPL.data.data, { 'numOrderId': numOrderId });

                    $('#viewOrderItemsModal').modal(
                        {
                            backdrop: 'static',
                            keyboard: false,
                            show: true
                        });
                }
            }
        ]);

}(window.angular));
