(function (angular) {
    "use strict";

    angular.module('myApp').component('adminPermissions', {
        templateUrl: '/adminPermissions/views/index.tpl.html',
        controller: 'AdminPermissionsController'
    });

    angular.module('myApp').controller('AdminPermissionsController',
        [
            '$scope', 'rishviList', "$state",'$rootScope',
            function ($scope, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/admin-permissions/';
                $scope.list.module = 'adminPermissions';
                $scope.list.actions = {
                    "delete": "adminPermissions.delete"
                };

                $scope.isEditPermissionsAccess = $rootScope.hasAccess("adminPermissions.edit");

                $scope.list.load();
                
                $scope.EditPermission = function (id) {
                    $state.go("adminPermissionsEdit", { id: id });
                }
            }
        ]);

}(window.angular));
