(function (angular) {
    "use strict";

    angular.module('myApp').component('inputCurrency', {
        templateUrl: '/inputCurrency/views/index.tpl.html',
        controller: 'inputCurrencyController'
    });

    angular.module('myApp').controller('inputCurrencyController',
        [
            '$scope', 'rishviList', "$state",'$rootScope',
            function ($scope, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/input-currency/';
                $scope.list.module = 'inputCurrency';
                $scope.list.actions = {
                    "delete": "inputCurrency.delete"
                };

                $scope.isEditInputCurrencyAccess = $rootScope.hasAccess("inputCurrency.edit");

                $scope.list.load();
                
                $scope.EditCurrency = function (id) {
                    $state.go("inputCurrencyEdit", { id: id });
                }
            }
        ]);

}(window.angular));
