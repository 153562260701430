(function (angular) {
    "use strict";

    angular.module('myApp').component('processOrderDetail',
        {
            templateUrl: '/orders/views/processOrderDetail.tpl.html',
            controller: ['$scope', '$state', 'processordersService',
                function ($scope, $state, processordersService) {

                    $scope.processOrderDetail = {};
                    var id = $state.params.id;
                    processordersService.getProcessOrderDetail(id).then(function (resp) {
                        if (resp.data.success) {
                            $scope.processOrderDetail = resp.data.data;
                        }
                    });
                }]
        });

}(window.angular));
