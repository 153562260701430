(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {
            //$stateProvider.state('orders', {
            //    parent: 'screen',
            //    url: '/orders',
            //    component: 'orders',
            //    data: {
            //        permissions: ['orders']
            //    }
            //})
            $stateProvider.state('orderView', {
                parent: 'screen',
                url: '/order-view',
                redirectTo: 'orders',
                component: 'orderView',
                data: {
                    permissions: ['orders']
                }
            })
                .state('orders', {
                    parent: 'orderView',
                    url: '/orders',
                    component: 'orders',
                    data: {
                        permissions: ['orders']
                    }
                })
                .state('orderEdit', {
                    parent: 'orderView',
                    url: '/orders/edit/{id}',
                    component: 'orderEdit'
                    //data: {
                    //    permissions: ['orders.edit']
                    //}
                })
                .state('processOrders', {
                    parent: 'orderView',
                    url: '/process-orders',
                    component: 'processOrders',
                    data: {
                        permissions: ['orders']
                    }
                })
                .state('processOrderView', {
                    parent: 'orderView',
                    url: '/process-orders/view/{id}',
                    redirectTo: 'processOrderDetail',
                    component: 'processOrderView',
                    data: {
                        permissions: ['orders']
                    }
                })
                .state('processOrderDetail', {
                    parent: 'processOrderView',
                    url: '/details',
                    component: 'processOrderDetail',
                    data: {
                        permissions: ['orders']
                    }
                })
                .state('processOrderAuditTrail', {
                    parent: 'processOrderView',
                    url: '/audit-trail',
                    component: 'processOrderAuditTrail',
                    data: {
                        permissions: ['orders']
                    }
                })
                .state('processOrderNote', {
                    parent: 'processOrderView',
                    url: '/notes',
                    component: 'processOrderNote',
                    data: {
                        permissions: ['orders']
                    }
                });
        }
    ]);

})(angular);
