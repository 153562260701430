(function (angular) {
    "use strict";

    angular.module('myApp').component('processOrders',
        {
            templateUrl: '/orders/views/processOrders.tpl.html',
            controller: ['$scope', '$state', 'rishviList', 'processordersService', '$filter', '$timeout',
                function ($scope, $state, rishviList, processordersService, $filter, $timeout) {
                   
                    $scope.openOrderFilterDto = {};

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/processorders/get-process-order';
                    $scope.list.module = 'processOrders';

                    $scope.list.load();

                    $timeout(function () {
                        $('#dDispatchBy').datetimepicker({
                            format: "dd/mm/yyyy",
                            todayHighlight: true,
                            autoclose: true,
                            startView: 2,
                            minView: 2,
                            forceParse: 0,
                            pickerPosition: 'bottom-right'
                        }).change(dispatchDateChanged);

                        $('#dProcessedOn').datetimepicker({
                            format: "dd/mm/yyyy",
                            todayHighlight: true,
                            autoclose: true,
                            startView: 2,
                            minView: 2,
                            forceParse: 0,
                            pickerPosition: 'bottom-right'
                        }).change(processedOnDateChanged);
                    }, 500);

                    function dispatchDateChanged() {
                        var dispatchDate = $('#dDispatchBy').val();
                        $scope.list.filters.dDispatchBy = dispatchDate.split('/')[1] + '/' + dispatchDate.split('/')[0] + '/' + dispatchDate.split('/')[2];
                        $scope.$apply();
                        $scope.list.load();
                    }

                    function processedOnDateChanged() {
                        var processedDate = $('#dProcessedOn').val();
                        $scope.list.filters.dProcessedOn = processedDate.split('/')[1] + '/' + processedDate.split('/')[0] + '/' + processedDate.split('/')[2];
                        $scope.$apply();
                        $scope.list.load();
                    }

                    $scope.viewOrder = function (orderId) {
                        $state.go("processOrderView", { id: orderId });
                    }

                    $scope.export = function () {
                        ordersService.export($scope.list.filters);
                    };

                    $scope.exportToExcel = function () {
                        $scope.filterData = {};
                        $scope.filterData.filterName = $scope.list.filters.filterName;
                        $scope.filterData.value = '';
                        processordersService.exportToExcel($scope.filterData).then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    };

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                    $scope.viewMoreOrderItems = function (numOrderId) {

                        $scope.viewOrder = $filter('filter')($scope.list.data.data, { 'numOrderId': numOrderId });

                        $('#viewOrderItemsModal').modal(
                            {
                                backdrop: 'static',
                                keyboard: false,
                                show: true
                            });
                    }
                }]
        });

}(window.angular));
