(function (angular) {
    "use strict";

    angular.module('myApp').component('categoryDuty', {
        templateUrl: '/categoryDuty/views/index.tpl.html',
        controller: 'categoryDutyController'
    });

    angular.module('myApp').controller('categoryDutyController',
        [
            '$scope', 'rishviList', "$state",'$rootScope',
            function ($scope, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/category-duty/';
                $scope.list.module = 'categoryDuty';
                $scope.list.actions = {
                    "delete": "categoryDuty.delete"
                };

                $scope.isEditCategoryDutyAccess = $rootScope.hasAccess("categoryDuty.edit");

                $scope.list.load();
                
                $scope.EditCategoryDuty = function (id) {
                    $state.go("categoryDutyEdit", { id: id });
                }
            }
        ]);

}(window.angular));
