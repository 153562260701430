(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('vendors', {
          parent: 'screen',
          url: '/vendors',
          component: 'vendors',
          data: {
            permissions: ['vendors']
          }
        })
        .state('vendorsCreate', {
          parent: 'screen',
          url: '/vendors/create',
          component: 'vendorsCreate',
          data: {
            permissions: ['vendors.create']
          }
        })
        .state('vendorsEdit', {
          parent: 'screen',
          url: '/vendors/edit/{id}',
          component: 'vendorsEdit',
          data: {
            permissions: ['vendors.edit']
          }
        });
    }
  ]);

})(angular);
