(function (angular) {
    "use strict";

    angular.module('myApp').component('salesAnalytics',
        {
            templateUrl: '/salesAnalytics/views/salesAnalytics.tpl.html',
            controller: ['$scope', '$state','rishviList', 'salesAnalyticsService',
                function ($scope, $state, rishviList, salesAnalyticsService) {

                    $scope.salesAnalyticsFilterDto = {};

                    var start = moment().subtract(30, 'days');
                    var end = moment();

                    $('#salesAnalyticsDateRange').daterangepicker({
                        buttonClasses: ' btn',
                        applyClass: 'btn-primary',
                        cancelClass: 'btn-secondary',

                        startDate: start,
                        endDate: end,
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Last 3 Months': [moment().subtract(3, 'month'), moment().subtract(0, 'month')],
                            'Last 6 Months': [moment().subtract(6, 'month'), moment().subtract(0, 'month')],
                            'Last 1 Year': [moment().subtract(1, 'year'), moment().subtract(0, 'year')]
                        }
                    }, function (start, end, label) {
                        $('#salesAnalyticsDateRange .form-control').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
                    });

                    $('#searchDate').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));


                    this.$onInit = function () {
                        //salesAnalyticsService.getProductCategoriesDropdown().then(function (resp) {
                        //    $scope.productCategories = resp.data;
                        //});

                        salesAnalyticsService.getAllSourceSubSourceDropdown().then(function (resp) {
                            $scope.sourceSubsource = resp.data;
                        });

                        //$scope.salesAnalytics();
                    }

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/sales-analytics/get-stock';
                    $scope.list.module = 'salesAnalytics';

                    var searchDate = $('#searchDate').val();
                    var dateFrom = searchDate.split('-')[0].split('/');
                    var dateTo = searchDate.split('-')[1].split('/');
                    $scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                    $scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                    $scope.list.load();

                    $scope.export = function () {
                        salesAnalyticsService.export($scope.list.filters);
                    };

                    //$scope.onAfterLoad = function () {
                    //    alert(1);
                    //}

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };


                    $scope.syncdata = function () {
                        $scope.stockItem();
                    }

                    $scope.checkSource = function (data) {
                        
                        var checkboxId = '#' + data;
                        var sourceName = 'input.' + data;
                        var checkboxes = document.querySelectorAll(sourceName);

                        var dataTable = $('#kt_datatable').DataTable();
                        var columns = dataTable.settings().init().columns;

                        dataTable.columns().every(function (index) {
                            if (columns[index].data !== 'Image'
                                || columns[index].data !== 'Product Type'
                                || columns[index].data !== 'SKU'
                                || columns[index].data !== 'Category'
                                || columns[index].data !== 'Title'
                                || columns[index].data !== 'InStock') {
                                dataTable.column(index).visible(false);
                            }
                        });

                        if ($(checkboxId).prop('checked')) {
                            for (var t = 0; t < checkboxes.length; t++) {
                                checkboxes[t].checked = true;
                            }
                        }
                        else {
                            for (var f = 0; f < checkboxes.length; f++) {
                                checkboxes[f].checked = false;
                            }
                        }

                        var checked = false;
                        $(".subsource-checkboxes input[type=checkbox]:checked").each(function () {
                            checked = true;
                            var checkbox = $(this);

                            dataTable.columns().every(function (index) {
                                if (columns[index].data === checkbox[0].id
                                    || columns[index].data === 'Image'
                                    || columns[index].data === 'Product Type'
                                    || columns[index].data === 'SKU'
                                    || columns[index].data === 'Category'
                                    || columns[index].data === 'Title'
                                    || columns[index].data === 'InStock') {
                                    //var colStatus = dataTable.column(index).visible();
                                    dataTable.column(index).visible(true);
                                }
                            });
                        });

                        if (!checked) {
                            //var columns = dataTable.settings().init().columns;
                            dataTable.columns().every(function (index) {
                                dataTable.column(index).visible(true);
                            });
                        }
                    }

                    $scope.checkSubSource = function (data) {

                        var dataTable = $('#kt_datatable').DataTable();
                        var columns = dataTable.settings().init().columns;
                        dataTable.columns().every(function (index) {
                            if (columns[index].data !== 'Image'
                                || columns[index].data !== 'Product Type'
                                || columns[index].data !== 'SKU'
                                || columns[index].data !== 'Category'
                                || columns[index].data !== 'Title'
                                || columns[index].data !== 'InStock') {
                                dataTable.column(index).visible(false);
                            }
                        });

                        var checked = false;                        
                        $(".subsource-checkboxes input[type=checkbox]:checked").each(function () {
                            checked = true;
                            
                            var checkbox = $(this);

                            dataTable.columns().every(function (index) {
                                if (columns[index].data === checkbox[0].id
                                    || columns[index].data === 'Product Type'
                                    || columns[index].data === 'SKU'
                                    || columns[index].data === 'Category'
                                    || columns[index].data === 'Title'
                                    || columns[index].data === 'InStock') {
                                    //var colStatus = dataTable.column(index).visible();
                                    dataTable.column(index).visible(true);
                                }
                            });
                        });

                        if (!checked) {
                            dataTable.columns().every(function (index) {
                                dataTable.column(index).visible(true);
                            });
                        }
                    }

                    $scope.stockItem = function () {
                        salesAnalyticsService.stockItem().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockExtendedproperties();
                            }
                        });
                    }

                    $scope.stockExtendedproperties = function () {
                        salesAnalyticsService.stockExtendedproperties().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockItemPricing()
                            }
                        });
                    }

                    $scope.stockItemPricing = function () {
                        salesAnalyticsService.stockItemPricing().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockItemChannelsku();
                            }
                        });
                    }

                    $scope.stockItemChannelsku = function () {
                        salesAnalyticsService.stockItemChannelsku().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockImagereg();
                            }
                        });
                    }

                    $scope.stockImagereg = function () {
                        salesAnalyticsService.stockImagereg().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockLocation();
                                //$scope.stockLevel();
                            }
                        });
                    }

                    $scope.stockLocation = function () {
                        salesAnalyticsService.stockLocation().then(function (resp) {
                            if (resp.data.success) {
                                $scope.stockLevel();
                            }
                        });
                    }

                    $scope.stockLevel = function () {
                        salesAnalyticsService.stockLevel().then(function (resp) {
                            if (resp.data.success) {
                                $scope.order();
                                Alert("success", "Stock Item Inserted Successfully");
                            }
                        });
                    }

                    $scope.order = function () {
                        salesAnalyticsService.order().then(function (resp) {
                            if (resp.data.success) {
                                $scope.orderItem();
                            }
                        });
                    }

                    $scope.orderItem = function () {
                        salesAnalyticsService.orderItem().then(function (resp) {
                            if (resp.data.success) {
                                Alert("success", "Order Item Inserted Successfully");
                            }
                        });
                    }

                    $scope.groupBySourceSubsource = function () {
                        salesAnalyticsService.getSourceSubSource().then(function (resp) {
                            if (resp.data.success) {
                                $scope.groupBySourceSubsoure = resp.data.data;
                                //console.log($scope.groupBySourceSubsoure);
                            }
                        });
                    }

                    $scope.salesAnalytics = function () {
                        $scope.scriptsListResult = {};
                        $scope.scriptsListColumn = {};

                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.salesAnalyticsFilterDto.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.salesAnalyticsFilterDto.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        //$scope.salesAnalyticsFilterDto.categoryId = $scope.productCategory;

                        salesAnalyticsService.getSalesAnalyticsList($scope.salesAnalyticsFilterDto).then(function (resp) {
                            $scope.salesAnalyticsList = resp.data.data;

                            if (resp.data.success === true && $scope.salesAnalyticsList.length > 0) {
                                $scope.salesAnalyticsListColumn = Object.keys($scope.salesAnalyticsList[0]);
                                $scope.salesAnalyticsListResult = $scope.salesAnalyticsList;
                                if ($.fn.DataTable.isDataTable('#kt_datatable')) {
                                    $('#kt_datatable').DataTable().destroy();
                                    $('#kt_datatable').empty();
                                }
                                var columnsData = [];
                                var tableHeader = "<thead><tr>";
                                angular.forEach($scope.salesAnalyticsListColumn, function (value, key) {
                                    tableHeader += "<th>" + value + "</th>"
                                    if (value === 'Image') {
                                        columnsData.push({
                                            data: value,
                                            render: function (data) {
                                                if (data != null) {
                                                    return '<img src="' + data + '" style="height:34px;" />';
                                                } else {
                                                    return '<img src="assets/media/default/no-image-available.png" style="height:34px;" >';
                                                }

                                            }
                                        });
                                    }
                                    else {
                                        columnsData.push({ data: value, mData: value });
                                    }
                                });
                                tableHeader += "</tr></thead>";
                                $("#kt_datatable").append(tableHeader)
                                setTimeout(function () {
                                    $scope.loadData($scope.salesAnalyticsListResult, columnsData);
                                }, 3000);

                                // }
                            }
                            else {
                                var blankTableHeader = "<thead><tr>";
                                blankTableHeader += "<th>Image</th>";
                                blankTableHeader += "<th>Product Type</th>";
                                blankTableHeader += "<th>SKU</th>";
                                blankTableHeader += "<th>Category</th>";
                                blankTableHeader += "<th>Title</th>";
                                blankTableHeader += "<th>InStock</th>";
                                blankTableHeader += "</tr></thead>";
                                var blankColumnsData = [];
                                blankColumnsData.push({ data: "Image", mData: "Image" });
                                blankColumnsData.push({ data: "Product Type", mData: "Product Type" });
                                blankColumnsData.push({ data: "SKU", mData: "SKU" });
                                blankColumnsData.push({ data: "Category", mData: "Category" });
                                blankColumnsData.push({ data: "Title", mData: "Title" });
                                blankColumnsData.push({ data: "InStock", mData: "InStock" });
                                $("#kt_datatable").append(blankTableHeader);

                                $scope.loadData($scope.salesAnalyticsListResult, blankColumnsData);
                            }
                        });
                    }

                    $scope.loadData = function (dataJSONArray, Columns) {

                        var salesAnalyticsFilterDto = new Object();
                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        salesAnalyticsFilterDto.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        salesAnalyticsFilterDto.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        
                        var table = $('#kt_datatable');
                        table.DataTable({
                            pageLength: 10,
                            scrollY: '70vh',
                            scrollX: true,
                            scrollCollapse: true,
                            bProcessing: true,
                            bServerSide: true,
                            //ajax: {
                            //    url: "/api/sales-analytics/get-stockk",
                            //    type: "POST",
                            //    datatype: "json",
                            //    data: salesAnalyticsFilterDto,
                            //    success: function (response) {
                            //        debugger;
                                    
                            //    }
                            //},
                            data: dataJSONArray,
                            columns: Columns,
                            destroy: true,
                            bSort: true,
                            columnDefs: [{
                                "targets": 'no-sort',
                                "orderable": false,
                            }],
                            buttons: [
                                'copy', 'csv', 'excel', 'pdf', 'print'
                            ]
                        });

                    }

                    $scope.searchSalesAnalytics = function () {    
                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                        $scope.list.load();
                    };

                    $scope.imageRemoveTumbnail = function (imageURL) {
                        
                        if (imageURL) {
                            return imageURL.replace('tumbnail_', '');
                        }
                        else {
                            return "";
                        }
                    };

                    //$scope.exportToExcel = function () {
                    //    //$scope.salesAnalytics();
                    //    $("#kt_datatable1").DataTable().button('.buttons-excel').trigger();
                    //}
                    $scope.exportToExcel = function () {
                        
                        $scope.filterData = {};
                        $scope.filterData.filterName = $scope.list.filters.filterName;
                        $scope.filterData.value = '';
                        $scope.filterData.exportType = 'excel';
                        $scope.filterData.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.filterData.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        salesAnalyticsService.exportToExcel($scope.filterData).then(function (resp) {

                            if (resp.data.success) {
                               
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    }

                    $scope.exportToPdf = function () {
                        $("#kt_datatable1").DataTable().button('.buttons-pdf').trigger();
                    }

                    $scope.exportToCSV = function () {
                        $scope.filterData = {};
                        $scope.filterData.filterName = $scope.list.filters.filterName;
                        $scope.filterData.value = '';
                        $scope.filterData.exportType = 'csv';
                        $scope.filterData.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.filterData.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];
                        salesAnalyticsService.exportToCSV($scope.filterData).then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                        //$("#kt_datatable1").DataTable().button('.buttons-csv').trigger();
                    }

                    $scope.downloadExcel = function () {
                        salesAnalyticsService.downloadExcel().then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                        //$("#kt_datatable1").DataTable().button('.buttons-csv').trigger();
                    }

                    function Alert(icon, title) {
                        Swal.fire({
                            position: "top-right",
                            icon: icon,
                            title: title,
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }
                    $scope.subSourceSalesCountData = [];
                    $scope.viewSubSourceSalesCount = function (Subsource, sku) {
                        $scope.subSourceSalesDto = {};
                        $scope.subSourceSalesDto.subsource = Subsource;
                        $scope.subSourceSalesDto.sKU = sku;
                        
                        var searchDate = $('#searchDate').val();
                        var dateFrom = searchDate.split('-')[0].split('/');
                        var dateTo = searchDate.split('-')[1].split('/');
                        $scope.subSourceSalesDto.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                        $scope.subSourceSalesDto.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                        salesAnalyticsService.getSubSourceSalesCount($scope.subSourceSalesDto).then(function (resp) {
                            if (resp.data.success) {

                                // TODO: Hold resp.data in scope object and bind that data in viewSubSourceSalesCountModal in salesAnalytics.tpl.html file
                                $scope.subSourceSalesCountData = resp.data.data;
                                $('#viewSubSourceSalesCountModal').modal(
                                    {
                                        backdrop: 'static',
                                        keyboard: false,
                                        show: true
                                    });
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    };

                }]
        });

}(window.angular));
