(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('inventory', {
                parent: 'screen',
                url: '/inventory',
                component: 'inventory',
                data: {
                    permissions: ['inventories']
                }
            });
        }
    ]);

})(angular);
