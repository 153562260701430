(function (angular) {
    "use strict";

    angular.module('myApp').component('reStock',
        {
            templateUrl: '/amazonPL/views/reStock.tpl.html',
            controller: ['$scope', 'rishviList', 'reStockService',
                function ($scope, rishviList, reStockService) {
                    $scope.list = new rishviList();
                    $scope.list.url = 'api/linnwork-restock/get-restock-list';
                    $scope.list.module = 'amazonpl';
                    $scope.list.filters.filterName = "General;object:186";

                    $scope.list.load();

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };
                    $scope.searchreStock = function () {
                        debugger;
                       
                        $scope.list.load();
                    };

                    // Freeze column
                    var gridViewScroll = null;
                    window.onload = function () {
                        gridViewScroll = new GridViewScroll({
                            elementID: "kt_datatable",
                            width: 850,
                            height: 350,
                            freezeColumn: true,
                            freezeFooter: true,
                            freezeColumnCssClass: "GridViewScrollItemFreeze",
                            freezeFooterCssClass: "GridViewScrollFooterFreeze",
                            freezeHeaderRowCount: 2,
                            freezeColumnCount: 3,
                            onscroll: function (scrollTop, scrollLeft) {
                                console.log(scrollTop + " - " + scrollLeft);
                            }
                        });
                        gridViewScroll.enhance();
                    }
                    // end Freeze Column


                    //define a date object variable that will take the current system date
                    var todaydate = new Date();

                    //find the year of the current date
                    var oneJan = new Date(todaydate.getFullYear(), 0, 1);

                    // calculating number of days in given year before a given date
                    var numberOfDays = Math.floor((todaydate - oneJan) / (24 * 60 * 60 * 1000));
                   
                    // adding 1 since to current date and returns value starting from 0
                    $scope.currentWeek = Math.ceil((todaydate.getDay() + 1 + numberOfDays) / 7);

                }]
        });

}(window.angular));
