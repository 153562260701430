(function (angular) {
  "use strict";

  angular.module('myApp').component("multiSelectCheckbox", {
    templateUrl: '/core/components/form/multiSelectCheckbox/multi-select-checkbox.tpl.html',
    transclude: true,
    bindings: {
      name: '@',
      label: '@?',
      required: '@?',
      data: '=',
      service: '=',
      buttonclick: '&',
      isbuttonshow: '@?',
      buttonlabel: '@?',
      permissions: '@?'
    },
    controller: ["$scope", '$timeout', '$filter', function ($scope, $timeout, $filter) {
      var self = this;

      self.$onInit = function () {
        $timeout(function () {
          if (self.service.dto !== undefined && self.service.dto[self.name] !== undefined) {

            if (self.service.dto[self.name].length === self.data.length)
              self.service.dto[self.name + 'All'] = true;
          }
        },
          500);
      };

      self.clicksearch = function (search) {

        if (self.service.dto[self.name] === undefined)
          self.service.dto[self.name] = [];

        var data = $filter('filter')(self.data, search).map(function (item) {
          return item.id;
        });

        var selectAll = true;
        for (var i = 0; i < data.length; i++) {
          if (self.service.dto[self.name].indexOf(data[i]) < 0) {
            selectAll = false;
            break;
          }
        }

        if (selectAll)
          self.service.dto[self.name + 'All'] = true;
        else
          self.service.dto[self.name + 'All'] = false;
      };

      self.clickCheckbox = function (search) {
        $timeout(function () {

          if (self.service.dto[self.name].length == $filter('filter')(self.data, search).length)
            self.service.dto[self.name + 'All'] = true;
          else
            self.service.dto[self.name + 'All'] = false;
        }, 50);

      };

      self.checkAll = function (search) {
        var data = $filter('filter')(self.data, search).map(function (item) {
          return item.id;
        });

        if (self.service.dto[self.name + 'All']) {
          self.service.dto[self.name + 'All'] = false;

          if (search === '')
            self.service.dto[self.name].splice(0, self.service.dto[self.name].length);

          else {
            for (var i = 0; i < data.length; i++) {
              var value = data[i];

              var matchIndex = self.service.dto[self.name].indexOf(value);
              if (matchIndex >= 0)
                self.service.dto[self.name].splice(matchIndex, 1);
            }
          }
        }
        else {
          if (self.service.dto[self.name] === undefined || search === '')
            self.service.dto[self.name] = [];

          self.service.dto[self.name + 'All'] = true;

          angular.forEach(data.map(function (item) {
            return item;
          }),
            function (value) {
              if (self.service.dto[self.name].indexOf(value) < 0)
                self.service.dto[self.name].push(value);
            });
        }
      };
    }]
  });

}(window.angular));

// special label click event
function funCheckboxClick(ctrl) {
  $(ctrl).siblings('label').click();
}
