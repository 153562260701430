(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('container', {
                parent: 'screen',
                url: '/container',
                component: 'container',
                data: {
                    permissions: ['container']
                }
            });
        }
    ]);

})(angular);
