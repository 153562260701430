(function (angular) {
    "use strict";

    angular.module('myApp').component('editProfile',
        {
            templateUrl: '/account/views/edit-profile.tpl.html',
            controller: 'EditProfileController'
        });

    angular.module('myApp').controller('EditProfileController',
        [
            '$scope', '$http', 'accountService',
            function ($scope, $http, accountService) {
                $scope.rishvi  = {};
                $scope.rishvi.dto = {};
                $scope.rishvi.result = {};                

                accountService.getEditProfile().then(function (resp) {
                    $scope.rishvi.dto = resp.data;
                    // setTimeout(function () { funInputPlaceholder(); }, 500);
                });

                $scope.submit = function () {
                    accountService.editProfile($scope.rishvi.dto)
                        .then(function (resp) {

                            $scope.rishvi.result = resp.data;
                            //flash.show(resp.data.message, resp.data.messageType);
                        });
                };
            }
        ]);

}(window.angular));