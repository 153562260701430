(function (angular) {
  "use strict";

  angular.module('myApp').component("selectBoxTwo", {
    templateUrl: "/core/components/form/selectBoxTwo/select-box-two.tpl.html",
    transclude: true,
    bindings: {
      name: '@',
      label: '@',
      required: '@',
      data: '=',
      service: '=',
      buttonclick: '&',
      isbuttonshow: '@?',
      buttonlabel: '@?',
      change: '&',
      isdisable: '@?',
      permissions: '@?',
      ishide: '@?',
      blankLabel: '@?'
    },
    controller: ["$animate", function ($animate) {
      $animate.enabled(false);
    }]
  });

}(window.angular));
