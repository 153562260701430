(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('shippingService', {
          parent: 'screen',
          url: '/shipping-services',
          component: 'shippingService',
          data: {
            permissions: ['shippingService']
          }
        })
        .state('shippingServiceCreate', {
          parent: 'screen',
          url: '/shipping-services/create',
          component: 'shippingServiceCreate',
          data: {
            permissions: ['shippingService.create']
          }
        })
        .state('shippingServiceEdit', {
          parent: 'screen',
          url: '/shipping-services/edit/{id}',
          component: 'shippingServiceEdit',
          data: {
            permissions: ['shippingService.edit']
          }
        });
    }
  ]);

})(angular);
