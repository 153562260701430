(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('containerService', [
            '$http',
            function ($http) {

                var urlBase = 'api/container-file/'

                this.getcontainerList = function (filters) {
                    return $http({
                        url: urlBase + 'get-product-container',
                        params: filters,
                        method: 'GET'
                    });
                };

                this.exportToExcel = function (dto) {
                    return $http({
                        url: urlBase + 'export-container',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
