(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('auth',
                {
                })
                .state('login',
                    {
                        parent: 'admin',
                        url: '/auth/login',
                        component: 'login'
                    })
                .state('forgotPassword',
                    {
                        parent: 'admin',
                        url: '/auth/forgot-password',
                        component: 'forgotPassword'
                    })
                .state('resetPassword',
                    {
                        parent: 'admin',
                        url: '/auth/reset-password/{token}',
                        component: 'resetPassword'
                    })
                .state('register',
                    {
                        parent: 'auth',
                        url: '/admin/register',
                        component: 'register'
                    })
                .state('logout',
                    {
                        parent: 'auth',
                        url: '/auth/logout'
                    });
        }]);

})(angular);
