(function (angular) {
  "use strict";

  angular.module('myApp').component("textboxDatetime", {
    templateUrl: "/core/components/form/textboxDatetime/textbox-datetime.tpl.html",
    transclude: true,
    bindings: {
      name: '@',
      required: '@',
      label: '@?',
      service: '='
    },
    controller: ['$scope',
      function ($scope) {

        this.$onInit=function(){
          $scope.picker = {

            datepickerOptions: {
              showWeeks: false,
              startingDay: 1,
              timezone: 'America/Los_Angeles'
            }
          };
        };

        $scope.openCalendar = function(e, picker) {
          $scope[picker].open = true;
        };
        $scope.changeDate = function () {
          setTimeout(function () { funInputPlaceholder(); }, 200);
        };
      }
    ]
  });

}(window.angular));
