(function (angular) {
  "use strict";

  angular.module('myApp').factory('rishviEdit', [
    "$state", "$window",
    function ($state, $window) {

      var rishviEdit = function () {

        this.listRoute = null;
        this.editRoute = null;

        this.service = null;

        this.id = ($state.params.childId != null && $state.params.childId != undefined ? $state.params.childId : $state.params.id);

        this.result = {};
        this.dto = {};

        //callback function
        this.onAfterSave = null;

        this.onAfterLoad = null;
        this.parentObj = false;

      };

      rishviEdit.prototype.load = function () {
        var $that = this;
          this.service.byId(this.id).then(function (resp) {
              
              if (!$that.parentObj) {
                  $that.dto = resp.data;
              }
              else {
                  $that.dto = DotObject.dot(resp.data);
              }
          // setTimeout(function () {
          //   funInputPlaceholder();
          // }, 500);

          if (typeof $that.onAfterLoad === "function")
            $that.onAfterLoad();

        }, function (error) {
          if (error.status === 404) {
            $state.go('404');
          }
        });
      };

      rishviEdit.prototype.save = function () {
        var $that = this;

        if (typeof $that.onBeforeSave === "function")
          $that.onBeforeSave();
          var dto;
          if ($that.parentObj) {
              dto = clone(this.dto);
              DotObject.object(dto);
          } else {
              dto = this.dto;
          }
          
          this.service.edit(this.id, dto)
          .then(function (resp) {

              $that.result = resp.data;
              Swal.fire({
                  position: "top-right",
                  icon: resp.data.messageType === 1 ? "success" : "error",
                  title: resp.data.message,
                  showConfirmButton: false,
                  timer: 2000
              });
            //flash.show(resp.data.message, resp.data.messageType);

            if (resp.data.success) {
              $state.go($that.listRoute);
              if (typeof $that.onAfterSave === "function")
                $that.onAfterSave();
            }

            $window.scrollTo(0, 0);
          });
      };

      rishviEdit.prototype.saveContinue = function () {
        var $that = this;

        if (typeof $that.onBeforeSave === "function")
          $that.onBeforeSave();

        this.service.edit(this.id, this.dto)
          .then(function (resp) {

              $that.result = resp.data;
              Swal.fire({
                  position: "top-right",
                  icon: resp.data.messageType === 1 ? "success" : "error",
                  title: resp.data.message,
                  showConfirmButton: false,
                  timer: 2000
              });
             //flash.show(resp.data.message, resp.data.messageType);

            if (typeof $that.onAfterLoad === "function")
              $that.onAfterLoad();

            $window.scrollTo(0, 0);
          });
      };

      rishviEdit.prototype.delete = function () {
        var $that = this;

        $.confirm({
          text: "Do you want to delete record?",
          title: "Confirmation required",
          confirmButtonClass: "btn-danger",
          confirm: function () {
            $that.service.delete([$that.id])
              .then(function (resp) {

                  $that.result = resp.data;
                  Swal.fire({
                      position: "top-right",
                      icon: resp.data.messageType === 1 ? "success" : "error",
                      title: resp.data.message,
                      showConfirmButton: false,
                      timer: 2000
                  });
                  //flash.show(resp.data.message, resp.data.messageType);

                if (resp.data.success) {
                  $state.go($that.listRoute);
                }

                $window.scrollTo(0, 0);
              });
          }
        });
      };

      return rishviEdit;
    }
  ]);

}(window.angular));
