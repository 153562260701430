(function (angular) {
  "use strict";

  angular.module('myApp').component('processOrderView', {
    templateUrl: '/orders/views/processOrderView.tpl.html',
    controller: [
      '$scope', 'ordersService', '$state',
      function ($scope, ordersService, $state) {
        
      }]
  });

}(window.angular));
