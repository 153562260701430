(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('processordersService', [
            '$http',
            function ($http) {

                var baseUrl = 'api/processorders/'

                this.getProcessOrderAuditTrail = function (id) {
                    return $http({
                        url: baseUrl + "get-process-order-audit-trail/" + id,
                        method: 'GET'
                    });
                };

                this.getProcessOrderDetail = function (id) {
                    return $http({
                        url: baseUrl + "get-process-order-detail/" + id,
                        method: 'GET'
                    });
                };
                this.exportToExcel = function (dto) {
                    
                    return $http({
                        url: baseUrl + 'export-processorders',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
