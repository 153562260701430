(function (angular) {
    "use strict";

    angular.module('myApp').component('categoryDutyEdit', {
        templateUrl: '/categoryDuty/views/edit.tpl.html',
        controller: 'categoryDutyEditController'
    });

    angular.module('myApp').controller('categoryDutyEditController',
        [
            '$scope', 'categoryDutyService', 'rishviEdit', '$timeout',
            function ($scope, categoryDutyService, rishviEdit, $timeout) {

                $scope.rishvi = new rishviEdit();
                $scope.rishvi.service = categoryDutyService;
                $scope.rishvi.listRoute = 'categoryDuty';
                $scope.rishvi.load();

                categoryDutyService.all().then(function (resp) {
                    $scope.categories = resp.data;
                });

            }
        ]);

}(window.angular));
