(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('salesAnalyticsService',
            [
                '$http', 'FileSaver', function ($http, FileSaver) {

                    var urlBase = 'api/sales-analytics-cron/';
                    var urlSales = 'api/sales-analytics/'

                    this.getSalesAnalyticsList = function (filters) {
                        return $http({
                            url: urlSales + 'get-stock',
                            params: filters,
                            method: 'GET'
                        });
                    }

                    this.getSourceSubSource = function () {
                        return $http({
                            url: urlSales + 'get-source-subsource',
                            method: 'GET'
                        });
                    }

                    this.order = function () {
                        return $http({
                            url: urlBase + 'order/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.orderItem = function () {
                        return $http({
                            url: urlBase + 'order-item/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockItem = function () {
                        return $http({
                            url: urlBase + 'stock-item/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockLocation = function () {
                        return $http({
                            url: urlBase + 'stock-location/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockExtendedproperties = function () {
                        return $http({
                            url: urlBase + 'stock-extendedproperties/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockItemPricing = function () {
                        return $http({
                            url: urlBase + 'stock-item-pricing/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockItemChannelsku = function () {
                        return $http({
                            url: urlBase + 'stock-item-channelsku/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockImagereg = function () {
                        return $http({
                            url: urlBase + 'stock-imagereg/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.stockLevel = function () {
                        return $http({
                            url: urlBase + 'stock-level/A42B1b144fe1fm',
                            method: 'GET'
                        });
                    };

                    this.getProductCategoriesDropdown = function () {
                        return $http({
                            url: urlSales + 'get-all-product-categories',
                            method: 'GET'
                        });
                    };

                    this.getAllSourceSubSourceDropdown = function () {
                        return $http({
                            url: urlSales + 'get-all-source-subsource',
                            method: 'GET'
                        });
                    };

                    this.export = function (filters) {
                        $http({ url: urlSales + 'export', params: filters, method: 'GET' })
                            .then(function (resp) {
                                var data = new Blob([resp.data], { type: 'text/csv;charset=utf-8' });
                                FileSaver.saveAs(data, 'sales-analytics.csv');
                            });
                    };
                    this.exportToExcel = function (dto) {
                        return $http({
                            url: urlSales + 'export-sales-analitic',
                            method: 'POST',
                            data: dto
                        });
                    };
                    this.exportToCSV = function (dto) {
                        return $http({
                            url: urlSales + 'export-sales-analitic',
                            method: 'POST',
                            data: dto
                        });
                    };

                    this.getSubSourceSalesCount = function (dto) {
                        return $http({
                            url: urlSales + 'get-sub-source-sales-count',
                            method: 'POST',
                            data: dto
                        });
                    };

                    this.getAllStockItems = function (dto) {
                        return $http({
                            url: urlSales + 'get-all-stock-items',
                            method: 'POST',
                            data: dto
                        });
                    };

                    this.getSupplierNativeCost = function (dto) {
                        return $http({
                            url: urlSales + 'get-supplier-native-cost',
                            method: 'POST',
                            data: dto
                        });
                    };

                    this.downloadExcel = function (dto) {
                        return $http({
                            url: 'api/sales-analytics/export-sales-analytic-excel',
                            method: 'POST',
                            data: dto
                        });
                    };
                }
            ]);

}(window.angular));