(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('reStockService', [
            '$http',
            function ($http) {
                var urlBase = 'api/linnwork-restock/'

                this.getReStockList = function (filters) {
                    return $http({
                        url: urlBase + 'get-restock-list',
                        params: filters,
                        method: 'GET'
                    });
                };

                this.exportToExcel = function (dto) {
                    return $http({
                        url: urlBase + 'export-restock',
                        method: 'POST',
                        data: dto
                    });
                };
            }
        ]);

}(window.angular));
