(function (angular) {
  "use strict";

    angular.module('myApp').component('inputCurrencyCreate', {
    templateUrl: '/inputCurrency/views/create.tpl.html',
    controller: 'inputCurrencyCreateController'
  });

  angular.module('myApp').controller('inputCurrencyCreateController',
    [
      '$scope', 'inputCurrencyService', 'rishviCreate',
      function ($scope, inputCurrencyService, rishviCreate) {

        $scope.rishvi = new rishviCreate();
        $scope.rishvi.service = inputCurrencyService;

        $scope.rishvi.listRoute = 'inputCurrency';
        $scope.rishvi.editRoute = 'inputCurrencyEdit';
        
      }
    ]);

}(window.angular));
