(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('home', {
        parent: 'admin',
        url: '/',
        component: 'adminDashboard'
      });
    }
  ]);

})(angular);
