(function (angular) {
    "use strict";

    angular.module('myApp').component('rejectedCalculator',
        {
            templateUrl: '/calculator/views/rejectedCalculator.tpl.html',
            controller: ['$scope', '$state', 'rishviList', 'calculatorService', '$filter',
                function ($scope, $state, rishviList, calculatorService, $filter) {

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/calculator/';
                    $scope.list.module = 'rejectedCalculator';
                    $scope.list.filters.calculatorStatus = 2;
                    $scope.list.load();

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                }]
        });

}(window.angular));
