(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('calculatorService', [
            '$http',
            function ($http) {

                var baseUrl = 'api/calculator/';

                this.validateNewCalculator = function (dto) {
                    return $http({
                        url: baseUrl + 'validate-new-calculator',
                        method: 'POST',
                        data: dto
                    });
                };

                this.validateEditNewCalculator = function (dto) {
                    return $http({
                        url: baseUrl + 'validate-edit-new-calculator',
                        method: 'POST',
                        data: dto
                    });
                };

                this.addNewCalculatorArray = function (dto) {
                    return $http({
                        url: baseUrl + 'add-new-calculator',
                        method: 'POST',
                        data: dto
                    });
                };

                this.editNewCalculatorArray = function (dto) {
                    return $http({
                        url: baseUrl + 'edit-new-calculator',
                        method: 'POST',
                        data: dto
                    });
                };

                this.createNewCalculators = function (dto) {
                    return $http({
                        url: baseUrl + 'create-new-calculators',
                        method: 'POST',
                        data: dto
                    });
                };

                this.editCalculators = function (id, dto) {
                    return $http({
                        url: baseUrl + id,
                        method: 'PUT',
                        data: dto
                    });
                };

                this.byId = function (id) {
                    return $http({
                        url: baseUrl + id,
                        method: 'GET'
                    });
                };
               
            }
        ]);

}(window.angular));
