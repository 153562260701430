(function (angular) {
    "use strict";

    angular.module('myApp').component('adminDashboard',
        {
            templateUrl: '/home/views/home.tpl.html',
            controller: ['$scope', '$state', 'dashboardService',
                function ($scope, $state, dashboardService) {

                    this.$onInit = function () {
                        dashboardService.statisticsCount().then(function (resp) {
                            $scope.statisticsCount = resp.data;
                        });

                        dashboardService.getSubSources(1).then(function (resp) {
                            $scope.subSources = resp.data.data;
                        });

                        dashboardService.getTopSellingProducts(1).then(function (resp) {
                            $scope.mostSellingProducts = resp.data.data;
                        });
                        $scope.bindTotalOrderAnalytics(3); //week
                        $scope.bindTotalRevenueAnalytics(3); //week
                    };

                    $scope.bindSubSources = function (flag) {
                        dashboardService.getSubSources(flag).then(function (resp) {
                            $scope.subSources = resp.data.data;
                        });
                    };

                    $scope.bindTopSellingProduct = function (flag) {
                        dashboardService.getTopSellingProducts(flag).then(function (resp) {
                            $scope.mostSellingProducts = resp.data.data;
                        });
                    };

                    $scope.bindTotalOrderAnalytics = function (flag) {
                        dashboardService.getTotalOrderAnalytics(flag).then(function (resp) {
                            if (resp.data.data) {
                                var WeekOrMonthCategories = resp.data.data.map(function (x) { return x.weekOrMonthName + " " + x.year });
                                var TotalOrders = resp.data.data.map(function (x) { return x.totalOrders });
                                $scope.showOrderChart(WeekOrMonthCategories, TotalOrders);
                            }
                        });
                    };

                    $scope.bindTotalRevenueAnalytics = function (flag) {
                        dashboardService.getTotalRevenueAnalytics(flag).then(function (resp) {
                            if (resp.data.data) {
                                var WeekOrMonthCategories = resp.data.data.map(function (x) { return x.weekOrMonthName + " " + x.year });
                                var TotalRevenue = resp.data.data.map(function (x) { return x.totalOrders });
                                $scope.showRevenueChart(WeekOrMonthCategories, TotalRevenue);
                            }
                        });
                    };



                                  
                    $scope.showOrderChart = function (WeekOrMonthCategories, TotalOrders) {
                        document.querySelector("#kt_charts_widget_1_chart_").innerHTML = "";
                        var options = {
                            series: [{
                                name: 'Total Order',
                                data: TotalOrders
                            }],
                            chart: {
                                type: 'bar',
                                height: 350,
                                toolbar: {
                                    show: false
                                }
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: ['30%'],
                                    endingShape: 'rounded'
                                },
                            },
                            legend: {
                                show: false
                            },
                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                            },
                            xaxis: {
                                categories: WeekOrMonthCategories,
                                axisBorder: {
                                    show: false,
                                },
                                axisTicks: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        //colors: KTApp.getSettings()['colors']['gray']['gray-500'],
                                        fontSize: '12px',
                                        //fontFamily: KTApp.getSettings()['font-family']
                                    }
                                }
                            },
                            yaxis: {
                                labels: {
                                    style: {
                                        //colors: KTApp.getSettings()['colors']['gray']['gray-500'],
                                        fontSize: '12px',
                                        //fontFamily: KTApp.getSettings()['font-family']
                                    }
                                }
                            },
                            fill: {
                                opacity: 1
                            },
                            states: {
                                normal: {
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                },
                                hover: {
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                },
                                active: {
                                    allowMultipleDataPointsSelection: false,
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                }
                            },
                            tooltip: {
                                style: {
                                    fontSize: '12px',
                                    //fontFamily: KTApp.getSettings()['font-family']
                                },
                                y: {
                                    formatter: function (val) {
                                        return val 
                                    }
                                }
                            },
                            //colors: [KTApp.getSettings()['colors']['theme']['base']['success'], KTApp.getSettings()['colors']['gray']['gray-300']],
                            grid: {
                                //borderColor: KTApp.getSettings()['colors']['gray']['gray-200'],
                                strokeDashArray: 4,
                                yaxis: {
                                    lines: {
                                        show: true
                                    }
                                }
                            }
                        };

                        var chart = new ApexCharts(document.querySelector("#kt_charts_widget_1_chart_"), options);
                        chart.render();
                    };

                    $scope.showRevenueChart = function (WeekOrMonthCategories, TotalRevenue) {
                        document.querySelector("#kt_charts_widget_3_chart_").innerHTML = "";
                        var options = {
                            series: [{
                                name: 'Total Revenue',
                                data: TotalRevenue
                            }],
                            chart: {
                                type: 'area',
                                height: 350,
                                toolbar: {
                                    show: false
                                }
                            },
                            plotOptions: {

                            },
                            legend: {
                                show: false
                            },
                            dataLabels: {
                                enabled: false
                            },
                            fill: {
                                type: 'solid',
                                opacity: 1
                            },
                            stroke: {
                                curve: 'smooth',
                                show: true,
                                width: 3,
                                //colors: [KTApp.getSettings()['colors']['theme']['base']['info']]
                            },
                            xaxis: {
                                categories: WeekOrMonthCategories,
                                axisBorder: {
                                    show: false,
                                },
                                axisTicks: {
                                    show: false
                                },
                                labels: {
                                    style: {
                                        //colors: KTApp.getSettings()['colors']['gray']['gray-500'],
                                        fontSize: '12px',
                                        //fontFamily: KTApp.getSettings()['font-family']
                                    }
                                },
                                crosshairs: {
                                    position: 'front',
                                    stroke: {
                                        //color: KTApp.getSettings()['colors']['theme']['base']['info'],
                                        width: 1,
                                        dashArray: 3
                                    }
                                },
                                tooltip: {
                                    enabled: true,
                                    formatter: undefined,
                                    offsetY: 0,
                                    style: {
                                        fontSize: '12px',
                                        //fontFamily: KTApp.getSettings()['font-family']
                                    }
                                }
                            },
                            yaxis: {
                                labels: {
                                    style: {
                                        //colors: KTApp.getSettings()['colors']['gray']['gray-500'],
                                        fontSize: '12px',
                                        //fontFamily: KTApp.getSettings()['font-family']
                                    }
                                }
                            },
                            states: {
                                normal: {
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                },
                                hover: {
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                },
                                active: {
                                    allowMultipleDataPointsSelection: false,
                                    filter: {
                                        type: 'none',
                                        value: 0
                                    }
                                }
                            },
                            tooltip: {
                                style: {
                                    fontSize: '12px',
                                    //fontFamily: KTApp.getSettings()['font-family']
                                },
                                y: {
                                    formatter: function (val) {
                                        return val.toLocaleString()
                                    }
                                }
                            },
                            //colors: [KTApp.getSettings()['colors']['theme']['light']['info']],
                            grid: {
                                //borderColor: KTApp.getSettings()['colors']['gray']['gray-200'],
                                strokeDashArray: 4,
                                yaxis: {
                                    lines: {
                                        show: true
                                    }
                                }
                            },
                            markers: {
                                //size: 5,
                                //colors: [KTApp.getSettings()['colors']['theme']['light']['danger']],
                                //strokeColor: KTApp.getSettings()['colors']['theme']['base']['info'],
                                strokeWidth: 3
                            }
                        };

                        var chart = new ApexCharts(document.querySelector("#kt_charts_widget_3_chart_"), options);
                        chart.render();
                    };

                }]
        });

}(window.angular));
