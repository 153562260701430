(function (angular) {
  "use strict";

  angular.module('myApp').component('vendorsCreate', {
    templateUrl: '/vendor/views/create.tpl.html',
    controller: 'vendorsCreateController'
  });

  angular.module('myApp').controller('vendorsCreateController',
    [
      '$scope', 'vendorsService', 'rishviCreate',
      function ($scope, vendorsService, rishviCreate) {

        $scope.rishvi = new rishviCreate();
        $scope.rishvi.service = vendorsService;

        $scope.rishvi.listRoute = 'vendors';
        $scope.rishvi.editRoute = 'vendorsEdit';
        
      }
    ]);

}(window.angular));
