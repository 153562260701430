(function (angular) {
    "use strict";

    angular.module('myApp').component('categoryDutyCreate', {
        templateUrl: '/categoryDuty/views/create.tpl.html',
        controller: 'categoryDutyCreateController'
    });

    angular.module('myApp').controller('categoryDutyCreateController',
        [
            '$scope', 'categoryDutyService', 'rishviCreate',
            function ($scope, categoryDutyService, rishviCreate) {

                $scope.rishvi = new rishviCreate();
                $scope.rishvi.service = categoryDutyService;

                $scope.rishvi.listRoute = 'categoryDuty';
                $scope.rishvi.editRoute = 'categoryDutyEdit';

                categoryDutyService.all().then(function (resp) {
                    $scope.categories = resp.data;
                });

            }
        ]);

}(window.angular));
