(function (angular) {
    "use strict";

    angular.module('myApp').component('outputCurrency', {
        templateUrl: '/outputCurrency/views/index.tpl.html',
        controller: 'outputCurrencyController'
    });

    angular.module('myApp').controller('outputCurrencyController',
        [
            '$scope', 'rishviList', "$state",'$rootScope',
            function ($scope, rishviList, $state, $rootScope) {

                $scope.list = new rishviList();
                $scope.list.url = 'api/output-currency/';
                $scope.list.module = 'outputCurrency';
                $scope.list.actions = {
                    "delete": "outputCurrency.delete"
                };

                $scope.isEditOutputCurrencyAccess = $rootScope.hasAccess("outputCurrency.edit");

                $scope.list.load();
                
                $scope.EditCurrency = function (id) {
                    $state.go("outputCurrencyEdit", { id: id });
                }
            }
        ]);

}(window.angular));
