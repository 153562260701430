(function (angular) {
  "use strict";

  angular.module('myApp').component('calculatorView', {
    templateUrl: '/calculator/views/calculatorView.tpl.html',
    controller: [
      '$scope', 'calculatorService', '$state',
      function ($scope, calculatorService, $state) {
        
      }]
  });

}(window.angular));
