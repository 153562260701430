(function (angular) {
  "use strict";

  angular.module('myApp').component('outputCurrencyEdit', {
    templateUrl: '/outputCurrency/views/edit.tpl.html',
    controller: 'outputCurrencyEditController'
  });

  angular.module('myApp').controller('outputCurrencyEditController',
    [
      '$scope', 'outputCurrencyService', 'rishviEdit', '$timeout',
      function ($scope, outputCurrencyService, rishviEdit, $timeout) {

        $scope.rishvi = new rishviEdit();
        $scope.rishvi.service = outputCurrencyService;
        $scope.rishvi.listRoute = 'outputCurrency';
        $scope.rishvi.load();
      }
    ]);

}(window.angular));
