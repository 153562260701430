(function (angular) {
    "use strict";

    angular.module('myApp').component('adminRolesCreate', {
        templateUrl: '/adminRoles/views/create.tpl.html',
        controller: 'adminRolesCreateController'
    });

    angular.module('myApp').controller('adminRolesCreateController',
        [
            '$scope', 'adminRolesService', 'adminPermissionsService', 'rishviCreate',
            function ($scope, adminRolesService, adminPermissionsService, rishviCreate) {

                $scope.rishvi = new rishviCreate();
                $scope.rishvi.service = adminRolesService;

                $scope.rishvi.listRoute = 'adminRoles';
                $scope.rishvi.editRoute = 'adminRolesEdit';

                adminPermissionsService.all().then(function (resp) {
                    
                    $scope.permissions = unflatten(resp.data);

                    setTimeout(function () {
                        checkUncheckRolePermission();
                    },
                        400);
                });

                //$scope.collapseAll = function () {
                //    $scope.$broadcast('angular-ui-tree:collapse-all');
                //};

                //$scope.expandAll = function () {
                //    $scope.$broadcast('angular-ui-tree:expand-all');
                //};

                function checkUncheckRolePermission() {
                    $('div.checkbox1 :checkbox').on('click',
                        function () {
                            var $chk = $(this),
                                $li = $chk.closest('li');
                            $li.find(':checkbox').prop('checked', this.checked);

                            $scope.rishvi.dto.permissions = $('div.checkbox1 :checkbox:checked').map(function () {
                                return $(this).attr('id');
                            }).get();
                        });
                }
            }
        ]);

}(window.angular));
