(function (angular) {
  "use strict";

  angular.module('myApp').component('register',
    {
      templateUrl: '/auth/views/register.tpl.html',
      controller: 'RegisterController'
    });

  angular.module('myApp').controller('RegisterController',
    [
      '$scope', '$http', 'authService', 'languageService', '$rootScope', '$state', '$cookies', 'flash', 'FileUploader',
      function ($scope, $http, authService, languageService, $rootScope,  $state, $cookies, flash, FileUploader) {

        $scope.dto = {};
        $scope.result = {};

        if ($rootScope.result) {
          $scope.result = $rootScope.result;
          $rootScope.result = null;
        }

        $scope.isDisableButton = false;
        $scope.tempUploadUrl = tempUploads;
        $scope.profileImageUploadUrl = memberProfileUpload;
        $scope.result.errors = {};
        $scope.profileUploader = new FileUploader({ url: "image-upload-filesize" });
        $scope.profileUploader.onAfterAddingFile = function (fileItem) {
          $scope.isDisableButton = true;
          fileItem.uploader.queue[0].upload();
        };
        $scope.profileUploader.onProgressItem = function (fileItem, progress) { };
        $scope.profileUploader.onCompleteItem = function (fileItem, response, status, headers) {
          if (response.success) {
            $scope.isDisableButton = false;
            $scope.dto.logo = response.data.filePath + response.data.fileName;
          } else {
            $scope.result.errors.logo = response.message;
            $scope.dto.logo = '';
            $scope.isDisableButton = true;
          }
          if (this._directives.select[0].element[0].value !== undefined)
            this._directives.select[0].element[0].value = '';
          fileItem.uploader.clearQueue();
        };

        languageService.getLanguages().then(function (resp) {
          $scope.languages = resp.data;
        });

        authService.getAllResturantCategory().then(function (resp) {
          $scope.resturantCategory = resp.data;
        });

        $scope.submit = function () {
          authService.register($scope.dto)
            .then(function (resp) {

              $scope.result = resp.data;

              if (resp.data.success) {
                var token = resp.data.data;

                //localStorageService.set(tokenName, token);
                //$cookies.put('auth', token);
                $state.go('login');
                flash.show("Register completed successfully", 1);
              }
            });
        };

        setTimeout(function () {
          funInputPlaceholder();
        }, 20);
      }
    ]);

}(window.angular));
