(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('inputCurrency', {
          parent: 'screen',
          url: '/input-currency',
          component: 'inputCurrency',
          data: {
            permissions: ['inputCurrency']
          }
        })
        .state('inputCurrencyCreate', {
          parent: 'screen',
          url: '/input-currency/create',
          component: 'inputCurrencyCreate',
          data: {
            permissions: ['inputCurrency.create']
          }
        })
        .state('inputCurrencyEdit', {
          parent: 'screen',
          url: '/input-currency/edit/{id}',
          component: 'inputCurrencyEdit',
          data: {
            permissions: ['inputCurrency.edit']
          }
        });
    }
  ]);

})(angular);
