(function (angular) {
  "use strict";

  angular.module('myApp').component("selectBoxTwoLazyLoad", {
    templateUrl: "/core/components/form/selectBoxTwoLazyLoad/select-box-two-lazy-load.tpl.html",
    transclude: true,
    bindings: {
      name: '@',
      label: '@',
      required: '@',
      data: '=',
      service: '=',
      buttonclick: '&',
      isbuttonshow: '@?',
      buttonlabel: '@?',
      change: '&',
      isdisable: '@?',
      permissions: '@?',
      ishide: '@?',
      blankLabel: '@?'
    },
    controller: ["$animate", "$scope", function ($animate, $scope) {
      $scope.currentElement = 50;
      $animate.enabled(false);
    }]
  });

}(window.angular));
