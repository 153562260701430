(function (angular) {
    "use strict";
  
    angular.module('myApp').directive("pager",
      function () {
        return {
          scope: {
            model: '='           
          },
          transclude: true,
          templateUrl: "/core/components/pager/pager.tpl.html"
        };
      });
  
  }(window.angular));
  