(function (angular) {
    "use strict";

    angular.module('myApp').component('container',
        {
            templateUrl: '/containers/views/container.tpl.html',
            controller: ['$scope', 'rishviList', 'containerService', 'Upload',
                function ($scope, rishviList, containerService, Upload) {

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/container-file/get-product-container';
                    $scope.list.module = 'container';
                    $scope.list.load();
                    $scope.header = [];
                    //$scope.containerFilter = {};
                    $scope.itemTotal = 0;
                    $scope.containerList = {};

                    $scope.list.onAfterLoad = function () {
                        var result = $.parseJSON($scope.list.data);
                        $.each(result[0], function (k, v) {
                            $scope.header.push(k);
                        });
                        $scope.itemTotal = result.length;
                        $scope.finalArray = [];
                        $.each(result, function (k, v) {
                            $scope.containerArray = [];
                            $.each(v, function (k1, v1) {
                                $scope.containerArray.push(v1);
                            });
                            $scope.finalArray.push($scope.containerArray);
                        });
                        $scope.containerList = $scope.finalArray;
                    };

                    $scope.uploadFiles = function (file, errFiles) {
                        $scope.f = file;
                        $scope.errFile = errFiles && errFiles[0];
                        $scope.upload = {};
                        $scope.upload.importPOFile = file;
                        if (file) {
                            file.upload = Upload.upload({
                                url: 'api/container-file/import',
                                method: 'POST',
                                data: $scope.upload
                            });

                            file.upload.then(function (resp) {
                                    console.log(resp);
                                    if (resp.data.success) {
                                        $scope.list.load();
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "success",
                                            title: "Excel import successfully.",
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    } else {
                                        Swal.fire({
                                            position: "top-right",
                                            icon: "error",
                                            title: resp.data.message,
                                            showConfirmButton: false,
                                            timer: 2000
                                        });
                                    }
                            }, function (resp) {
                                //if (resp.status > 0)
                                //    $scope.errorMsg = resp.status + ': ' + resp.data;
                            }, function (evt) {
                                file.progress = Math.min(100, parseInt(100.0 *
                                    evt.loaded / evt.total));
                            });
                        }
                    };

                    $scope.searchContainer = function () {
                        $scope.list.load();
                    };

                    $scope.exportToExcel = function ()
                    {
                        containerService.exportToExcel($scope.list.filters).then(function (resp) {
                            if (resp.data.success) {

                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    }
                }]
        });

}(window.angular));
