(function (angular) {
  "use strict";

  angular.module('myApp').component("textboxFile", {
    templateUrl: "/core/components/form/textboxFile/textbox-file.tpl.html",
    transclude: true,
    bindings: {
      name: '@',
      label: '@?',
      required: '@',
      service: '=',
      isdisable: '@?'
    },
    controller: ["$scope", function ($scope) {
      var $that = this;

      this.browseFiles = function () {        
          CKFinder.modal({
            chooseFiles: true,
            width: 1024,
            height: 800,
            onInit: function (finder) {
              finder.on('files:choose', function (evt) {
                var file = evt.data.files.first();
                $that.service.dto[$that.name] = file.getUrl();

                $scope.$apply();
              });

              finder.on('file:choose:resizedImage', function (evt) {

                $that.service.dto[$that.name] = evt.data.resizedUrl;

                $scope.$apply();
              });
            }
          });       
        // var finderImage;
        // if (window.CKFinder)
        //   finderImage = new CKFinder();

        // finderImage.selectActionFunction = function (fileUrl) {

        //   var fileUrlWithoutVirtualDir = fileUrl.replace(virtualDir, "");
        //   $that.service.dto[$that.name] = fileUrlWithoutVirtualDir;
        //   $scope.$apply();
        // };
        // finderImage.popup();
      };

      this.removeFile = function () {        
          $that.service.dto[$that.name] = null;
      };
    }]
  });

}(window.angular));
