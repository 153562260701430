(function (angular) {
    "use strict";

    angular.module('myApp').component('orders',
        {
            templateUrl: '/orders/views/orders.tpl.html',
            controller: ['$scope', '$state', 'rishviList', 'ordersService', '$filter',
                function ($scope, $state, rishviList, ordersService, $filter) {

                    $scope.openOrderFilterDto = {};

                    //var start = moment().subtract(30, 'days');
                    //var end = moment();

                    //$('#openOrderDateRange').daterangepicker({
                    //    buttonClasses: ' btn',
                    //    applyClass: 'btn-primary',
                    //    cancelClass: 'btn-secondary',

                    //    startDate: start,
                    //    endDate: end,
                    //    ranges: {
                    //        'Today': [moment(), moment()],
                    //        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    //        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    //        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    //        'This Month': [moment().startOf('month'), moment().endOf('month')],
                    //        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    //        'Last 3 Months': [moment().subtract(3, 'month'), moment().subtract(0, 'month')],
                    //        'Last 6 Months': [moment().subtract(6, 'month'), moment().subtract(0, 'month')],
                    //        'Last 1 Year': [moment().subtract(1, 'year'), moment().subtract(0, 'year')]
                    //    }
                    //}, function (start, end, label) {
                    //        $('#openOrderDateRange .form-control').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
                    //});

                    //$('#searchDate').val(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));


                    $scope.list = new rishviList();
                    $scope.list.url = 'api/orders/get-open-order';
                    $scope.list.module = 'orders';
                    $scope.list.filters.additionalFilter = "";
                    $scope.list.filters.filterName = "";
                    //var searchDate = $('#searchDate').val();
                    //var dateFrom = searchDate.split('-')[0].split('/');
                    //var dateTo = searchDate.split('-')[1].split('/');
                    //$scope.list.filters.startDate = dateFrom[1] + '-' + dateFrom[0] + '-' + dateFrom[2];
                    //$scope.list.filters.endDate = dateTo[1] + '-' + dateTo[0] + '-' + dateTo[2];

                    $scope.list.load();

                    $scope.exportToExcel = function () {
                        $scope.filterData = {};
                        $scope.filterData.filterName = $scope.list.filters.filterName;
                        $scope.filterData.value = '';
                        ordersService.exportToExcel($scope.filterData).then(function (resp) {

                            if (resp.data.success) {
                                var win = window.open(resp.data.data, '_blank');
                                if (win) {
                                    //Browser has allowed it to be opened
                                    win.focus();
                                } else {
                                    //Browser has blocked it
                                    alert('Please allow popups for this website');
                                }
                            }
                            else {
                                Swal.fire({
                                    position: "top-right",
                                    icon: resp.data.messageType === 1 ? "success" : "error",
                                    title: resp.data.message,
                                    showConfirmButton: false,
                                    timer: 2000
                                });
                            }
                        });
                    };

                    //$scope.searchOpenOrder = function () {
                    //    $scope.list.load();
                    //};

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                    $scope.viewMoreOrderItems = function (numOrderId) {

                        $scope.viewOrder = $filter('filter')($scope.list.data.data, { 'numOrderId': numOrderId });

                        $('#viewOrderItemsModal').modal(
                            {
                                backdrop: 'static',
                                keyboard: false,
                                show: true
                            });
                    }

                    $scope.EditOrder = function (orderId) {
                        $state.go("orderEdit", { id: orderId });
                    }

                    $scope.filterOptionChange = function () {
                        if ($scope.list.filters.filterName === "") {
                            $scope.list.filters.additionalFilter = "";
                        }
                    }

                    $scope.searchOpenOrder = function () {
                        $scope.list.load(true);
                    }
                }]
        });

}(window.angular));
