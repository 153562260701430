(function (angular) {
  "use strict";

  angular.module('myApp').component('inputCurrencyEdit', {
    templateUrl: '/inputCurrency/views/edit.tpl.html',
    controller: 'inputCurrencyEditController'
  });

  angular.module('myApp').controller('inputCurrencyEditController',
    [
      '$scope', 'inputCurrencyService', 'rishviEdit', '$timeout',
      function ($scope, inputCurrencyService, rishviEdit, $timeout) {

        $scope.rishvi = new rishviEdit();
        $scope.rishvi.service = inputCurrencyService;
        $scope.rishvi.listRoute = 'inputCurrency';
        $scope.rishvi.load();
      }
    ]);

}(window.angular));
