(function (angular) {
  "use strict";

  angular.module('myApp').component('orderView', {
    templateUrl: '/orders/views/orderView.tpl.html',
    controller: [
      '$scope', 'ordersService', '$state',
      function ($scope, ordersService, $state) {
        //$scope.id = $state.params.id;
      
        //$scope.memberId = 0;
        //$scope.loginAs = function () {
        //  membersService.getMemberByPractice($scope.id).then(function (resp) {            
        //    $scope.memberId = resp.data[0].id;
        //    if ($scope.memberId > 0) {
        //      window.open(
        //        virtualDir + 'admin-login-as/' + $scope.memberId,
        //        '_blank'
        //      );
        //    }
        //  });
          
        //};
      }]
  });

}(window.angular));
