(function (angular) {
  "use strict";

  angular.module('myApp').component('vendorsEdit', {
    templateUrl: '/vendor/views/edit.tpl.html',
    controller: 'vendorsEditController'
  });

  angular.module('myApp').controller('vendorsEditController',
    [
      '$scope', 'vendorsService', 'rishviEdit', '$timeout',
      function ($scope, vendorsService, rishviEdit, $timeout) {

        $scope.rishvi = new rishviEdit();
        $scope.rishvi.service = vendorsService;
        $scope.rishvi.listRoute = 'vendors';
        $scope.rishvi.load();
      }
    ]);

}(window.angular));
