(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('dashboardService', [
            '$http',
            function ($http) {

                var urlBase = 'api/dashboard/';

                this.statisticsCount = function () {
                    return $http({
                        url: urlBase + 'get-all-statistics-count',
                        method: 'GET'
                    });
                };

                this.getSubSources = function (id) {
                    return $http({
                        url: urlBase + 'get-sub-sources/' + id,
                        method: 'GET'
                    });
                };

                this.getTopSellingProducts = function (id) {
                    return $http({
                        url: urlBase + 'get-top-selling-products/' + id,
                        method: 'GET'
                    });
                };

                this.getTotalOrderAnalytics = function (id) {
                    return $http({
                        url: urlBase + 'get-total-order-analytics/' + id,
                        method: 'GET'
                    });
                };

                this.getTotalRevenueAnalytics = function (id) {
                    return $http({
                        url: urlBase + 'get-total-revenue-analytics/' + id,
                        method: 'GET'
                    });
                };

            }
        ]);

}(window.angular));
