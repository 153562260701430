(function (angular) {
    "use strict";

    angular.module('myApp').component('orderEdit',
        {
            templateUrl: '/orders/views/orderEdit.tpl.html',
            controller: ['$scope', '$state', 'rishviEdit', 'ordersService',
                function ($scope, $state, rishviEdit, ordersService) {

                    $scope.rishvi = new rishviEdit();
                    $scope.rishvi.service = ordersService;
                    $scope.rishvi.listRoute = 'orders';
                    //$scope.rishvi.parentObj = true;
                    $scope.stockItemsSearch = {};
                    $scope.rishvi.dto.orderItemObj = {};

                    $scope.tempShipping = {};
                    $scope.tempBilling = {};

                    $scope.isBilling = false;

                    $scope.countries = {};
                    ordersService.getAllCountry().then(function (resp) {
                        $scope.countries = resp.data;
                    });

                    $scope.postalServices = {};
                    ordersService.getpostalServices().then(function (resp) {
                        $scope.postalServices = resp.data;
                    });

                    $scope.orderStatuses = [
                        {
                            "id": 0,
                            "name": "NotPaid"
                        },
                        {
                            "id": 1,
                            "name": "Paid"
                        },
                        {
                            "id": 2,
                            "name": "Return"
                        },
                        {
                            "id": 3,
                            "name": "Pending"
                        },
                        {
                            "id": 4,
                            "name": "Resend"
                        }
                    ];

                    $scope.orderPaymentMethods = [
                        {
                            "id": "00000000-0000-0000-0000-000000000000",
                            "name": "Default"
                        },
                        {
                            "id": "e82680a4-5cf4-4299-b4f6-37a595b83709",
                            "name": "Cash"
                        },
                        {
                            "id": "7693377a-b69a-4939-8d0b-842a21923cf4",
                            "name": "Paypal"
                        },
                        {
                            "id": "af7c0518-4111-4f6f-9543-85cb604c6bfd",
                            "name": "Card"
                        }
                    ];

                    $scope.packagingGroups = [
                        {
                            "id": "00000000-0000-0000-0000-000000000000",
                            "name": "Default"
                        },
                        {
                            "id": "71933444-7591-4b4c-bb25-cb319180a2d4",
                            "name": "Packet - Jiffy Bag"
                        },
                        {
                            "id": "e10b7b91-0ec5-4cfc-8598-4b188a3666ef",
                            "name": "Packet - Jiffy Bag (Chunky Light products)"
                        },
                        {
                            "id": "31873973-74d6-4830-b6c2-e34f08576729",
                            "name": "Parcel"
                        },
                        {
                            "id": "a5a6c60e-9dc6-4f9d-a8d6-0dcc5f99040b",
                            "name": "TSG-PG"
                        }
                    ];

                    $scope.packagingTypes = [
                        {
                            "id": "00000000-0000-0000-0000-000000000000",
                            "name": "Default"
                        }
                    ];

                    $scope.orderPaymentMethods = [
                        {
                            "id": "00000000-0000-0000-0000-000000000000",
                            "name": "Default"
                        }
                    ];

                    $scope.rishvi.load();

                    $scope.rishvi.onAfterLoad = function () {
                        var clonedata = clone($scope.rishvi.dto);
                        var convertDto = DotObject.object(clonedata);
                        var items = convertDto.items;

                        $scope.rishvi.dto = DotObject.object(clonedata);
                        $scope.rishvi.dto.items = [];
                        $scope.rishvi.dto = DotObject.dot($scope.rishvi.dto);
                        $scope.rishvi.dto.items = items;

                        ordersService.getOrderPackagingCalculation($scope.rishvi.dto.orderId).then(function (resp) {

                            if (resp.data.success) {
                                $scope.getOrderPackaging = resp.data.data;

                                $scope.rishvi.dto.packagingWeight = $scope.getOrderPackaging.packagingWeight;
                                $scope.rishvi.dto.dimension = $scope.getOrderPackaging.dimension;

                                $scope.rishvi.dto.fkPackagingGroupId = $scope.getOrderPackaging.fkPackagingGroupId;
                                $scope.rishvi.dto.fkPackagingTypeId = $scope.getOrderPackaging.fkPackagingTypeId;
                                $scope.rishvi.dto.packagingwidth = $scope.getOrderPackaging.totalWidth;
                                $scope.rishvi.dto.packagingheight = $scope.getOrderPackaging.totalHeight;
                                $scope.rishvi.dto.packagingdepth = $scope.getOrderPackaging.totalDepth;
                                $scope.rishvi.dto.packagingTotalWeight = $scope.getOrderPackaging.totalWeight;
                            }
                        });
                    }

                    $scope.saveShipping = function () {
                        $scope.rishvi.dto['shippingAddress.country'] = $(".shippingAddressCountryId option:selected").text();

                        if ($scope.isBilling) {
                            $scope.rishvi.dto['billingAddress.emailAddress'] = $scope.rishvi.dto['shippingAddress.emailAddress'];
                            $scope.rishvi.dto['billingAddress.address1'] = $scope.rishvi.dto['shippingAddress.address1'];
                            $scope.rishvi.dto['billingAddress.address2'] = $scope.rishvi.dto['shippingAddress.address2'];
                            $scope.rishvi.dto['billingAddress.address3'] = $scope.rishvi.dto['shippingAddress.address3'];
                            $scope.rishvi.dto['billingAddress.region'] = $scope.rishvi.dto['shippingAddress.region'];
                            $scope.rishvi.dto['billingAddress.town'] = $scope.rishvi.dto['shippingAddress.town'];
                            $scope.rishvi.dto['billingAddress.postCode'] = $scope.rishvi.dto['shippingAddress.postCode'];
                            $scope.rishvi.dto['billingAddress.country'] = $(".shippingAddressCountryId option:selected").text();
                            $scope.rishvi.dto['billingAddress.countryId'] = $scope.rishvi.dto['shippingAddress.countryId'];
                            $scope.rishvi.dto['billingAddress.fullName'] = $scope.rishvi.dto['shippingAddress.fullName'];
                            $scope.rishvi.dto['billingAddress.company'] = $scope.rishvi.dto['shippingAddress.company'];
                            $scope.rishvi.dto['billingAddress.phoneNumber'] = $scope.rishvi.dto['shippingAddress.phoneNumber'];
                        }
                    }

                    $scope.saveBilling = function () {
                        $scope.rishvi.dto['billingAddress.country'] = $(".billingAddressCountryId option:selected").text();
                    }

                    $scope.closeShipping = function () {
                        $scope.rishvi.dto['shippingAddress.emailAddress'] = $scope.tempShipping.emailAddress
                        $scope.rishvi.dto['shippingAddress.address1'] = $scope.tempShipping.address1;
                        $scope.rishvi.dto['shippingAddress.address2'] = $scope.tempShipping.address2;
                        $scope.rishvi.dto['shippingAddress.address3'] = $scope.tempShipping.address3;
                        $scope.rishvi.dto['shippingAddress.region'] = $scope.tempShipping.region;
                        $scope.rishvi.dto['shippingAddress.town'] = $scope.tempShipping.town;
                        $scope.rishvi.dto['shippingAddress.postCode'] = $scope.tempShipping.postCode;
                        $scope.rishvi.dto['shippingAddress.country'] = $scope.tempShipping.country;
                        $scope.rishvi.dto['shippingAddress.countryId'] = $scope.tempShipping.countryId;
                        $scope.rishvi.dto['shippingAddress.fullName'] = $scope.tempShipping.fullName;
                        $scope.rishvi.dto['shippingAddress.company'] = $scope.tempShipping.company;
                        $scope.rishvi.dto['shippingAddress.phoneNumber'] = $scope.tempShipping.phoneNumber;
                    }

                    $scope.closeBilling = function () {
                        $scope.rishvi.dto['billingAddress.emailAddress'] = $scope.tempBilling.emailAddress
                        $scope.rishvi.dto['billingAddress.address1'] = $scope.tempBilling.address1;
                        $scope.rishvi.dto['billingAddress.address2'] = $scope.tempBilling.address2;
                        $scope.rishvi.dto['billingAddress.address3'] = $scope.tempBilling.address3;
                        $scope.rishvi.dto['billingAddress.region'] = $scope.tempBilling.region;
                        $scope.rishvi.dto['billingAddress.town'] = $scope.tempBilling.town;
                        $scope.rishvi.dto['billingAddress.postCode'] = $scope.tempBilling.postCode;
                        $scope.rishvi.dto['billingAddress.country'] = $scope.tempBilling.country;
                        $scope.rishvi.dto['billingAddress.countryId'] = $scope.tempBilling.countryId;
                        $scope.rishvi.dto['billingAddress.fullName'] = $scope.tempBilling.fullName;
                        $scope.rishvi.dto['billingAddress.company'] = $scope.tempBilling.company;
                        $scope.rishvi.dto['billingAddress.phoneNumber'] = $scope.tempBilling.phoneNumber;
                    }

                    $scope.editShipping = function () {
                        $scope.tempShipping.emailAddress = $scope.rishvi.dto['shippingAddress.emailAddress'];
                        $scope.tempShipping.address1 = $scope.rishvi.dto['shippingAddress.address1'];
                        $scope.tempShipping.address2 = $scope.rishvi.dto['shippingAddress.address2'];
                        $scope.tempShipping.address3 = $scope.rishvi.dto['shippingAddress.address3'];
                        $scope.tempShipping.region = $scope.rishvi.dto['shippingAddress.region'];
                        $scope.tempShipping.town = $scope.rishvi.dto['shippingAddress.town'];
                        $scope.tempShipping.postCode = $scope.rishvi.dto['shippingAddress.postCode'];
                        $scope.tempShipping.country = $(".shippingAddressCountryId option:selected").text();
                        $scope.tempShipping.countryId = $scope.rishvi.dto['shippingAddress.countryId'];
                        $scope.tempShipping.fullName = $scope.rishvi.dto['shippingAddress.fullName'];
                        $scope.tempShipping.company = $scope.rishvi.dto['shippingAddress.company'];
                        $scope.tempShipping.phoneNumber = $scope.rishvi.dto['shippingAddress.phoneNumber'];
                    };

                    $scope.editBilling = function () {
                        $scope.tempBilling.emailAddress = $scope.rishvi.dto['billingAddress.emailAddress'];
                        $scope.tempBilling.address1 = $scope.rishvi.dto['billingAddress.address1'];
                        $scope.tempBilling.address2 = $scope.rishvi.dto['billingAddress.address2'];
                        $scope.tempBilling.address3 = $scope.rishvi.dto['billingAddress.address3'];
                        $scope.tempBilling.region = $scope.rishvi.dto['billingAddress.region'];
                        $scope.tempBilling.town = $scope.rishvi.dto['billingAddress.town'];
                        $scope.tempBilling.postCode = $scope.rishvi.dto['billingAddress.postCode'];
                        $scope.tempBilling.country = $(".billingAddressCountryId option:selected").text();
                        $scope.tempBilling.countryId = $scope.rishvi.dto['billingAddress.countryId'];
                        $scope.tempBilling.fullName = $scope.rishvi.dto['billingAddress.fullName'];
                        $scope.tempBilling.company = $scope.rishvi.dto['billingAddress.company'];
                        $scope.tempBilling.phoneNumber = $scope.rishvi.dto['billingAddress.phoneNumber'];
                    };

                    $scope.addNewItem = function () {
                        $scope.stockItemsSearch.keyWord = '';
                        $scope.stockItems = [];
                        $('#addNewStockIemModal').modal('show');
                        $("#btnSaveService").prop('disabled', false);
                    }

                    $scope.getStockItems = function () {
                        getSearchStockItems();
                    }

                    $("#stockItemSearchKeyword").keyup(function (event) {
                        if (event.keyCode === 13) {
                            getSearchStockItems();
                        }
                    });

                    function getSearchStockItems() {

                        if ($scope.stockItemsSearch.keyWord.length < 3) {
                            $scope.isSearchError = "'Search' length must be three or more character.";
                            return;
                        }
                        $scope.isSearchError = '';

                        $scope.stockItemsSearch.locationId = "00000000-0000-0000-0000-000000000000";
                        $scope.stockItemsSearch.entriesPerPage = 100;
                        $scope.stockItemsSearch.pageNumber = 1;
                        $scope.stockItemsSearch.excludeComposites = false;
                        $scope.stockItemsSearch.excludeVariations = false;
                        $scope.stockItemsSearch.excludeBatches = false;

                        ordersService.getStockItems($scope.stockItemsSearch).then(function (resp) {
                            $scope.stockItems = resp.data.data;
                        });
                    }

                    $(document).on('change', "input[type='checkbox']", function () {
                        $('.stockItemCheckbox').not(this).prop('checked', false);

                        $("#btnSaveService").prop('disabled', true);
                    });

                    $scope.addOrderItem = function () {

                        var enableSaveButton = false;
                        $scope.addOrderItemDto = {};
                        $("#tblStockItems input[type=checkbox]:checked").each(function () {

                            enableSaveButton = true;
                            $scope.addOrderItemDto.itemId = $(this).data('stockid');

                            $scope.addOrderItemDto.pricePerUnit = $(this).data('retailprice');
                            $scope.addOrderItemDto.taxRatePercentage = $(this).data('taxrate');
                        });

                        $scope.addOrderItemDto.orderId = $scope.rishvi.dto.orderId;


                        if (enableSaveButton) {
                            ordersService.addOrderItem($scope.addOrderItemDto).then(function (resp) {

                                if (resp.data.success) {
                                    $scope.addOrderItemResponseDto = resp.data.data;
                                    // update details after order item add
                                    $scope.newOrderItem = {};
                                    $scope.newOrderItem.itemId = $scope.addOrderItemResponseDto.item.itemId;
                                    $scope.newOrderItem.itemNumber = $scope.addOrderItemResponseDto.item.itemNumber;
                                    $scope.newOrderItem.sku = $scope.addOrderItemResponseDto.item.sku;
                                    $scope.newOrderItem.title = $scope.addOrderItemResponseDto.item.title;
                                    $scope.newOrderItem.cost = $scope.addOrderItemResponseDto.item.cost;
                                    $scope.newOrderItem.unitCost = $scope.addOrderItemResponseDto.item.unitCost;
                                    $scope.newOrderItem.costIncTax = $scope.addOrderItemResponseDto.item.costIncTax;
                                    $scope.newOrderItem.discount = $scope.addOrderItemResponseDto.item.discount;
                                    $scope.newOrderItem.quantity = $scope.addOrderItemResponseDto.item.quantity;
                                    $scope.newOrderItem.tax = $scope.addOrderItemResponseDto.item.tax;
                                    $scope.newOrderItem.taxRate = $scope.addOrderItemResponseDto.item.taxRate;

                                    $scope.rishvi.dto.items.push($scope.newOrderItem);

                                    $scope.rishvi.dto.numItems = $scope.rishvi.dto.numItems + 1;
                                    $scope.rishvi.dto.itemWeight = $scope.addOrderItemResponseDto.itemWeight;
                                    $scope.rishvi.dto.stockValue = $scope.rishvi.dto.stockValue + $scope.addOrderItemResponseDto.unitCost;

                                    $scope.rishvi.dto.totalWeight = $scope.addOrderItemResponseDto.totalWeight;

                                    $scope.rishvi.dto.countryTaxRate = $scope.addOrderItemResponseDto.totalsInfo.countryTaxRate;
                                    $scope.rishvi.dto.totalDiscount = $scope.addOrderItemResponseDto.totalsInfo.totalDiscount;
                                    $scope.rishvi.dto.subtotal = $scope.addOrderItemResponseDto.totalsInfo.subtotal;
                                    //$scope.rishvi.dto.postageCostExTax = $scope.addOrderItemResponseDto.totalsInfo.postageCostExTax;
                                    $scope.rishvi.dto.tax = $scope.addOrderItemResponseDto.totalsInfo.tax;
                                    $scope.rishvi.dto.totalCharge = $scope.addOrderItemResponseDto.totalsInfo.totalCharge;

                                    Swal.fire({
                                        position: "top-right",
                                        icon: resp.data.messageType === 1 ? "success" : "error",
                                        title: resp.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                }
                                else {
                                    Swal.fire({
                                        position: "top-right",
                                        icon: resp.data.messageType === 1 ? "success" : "error",
                                        title: resp.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                }

                            });
                        }
                        else {
                            Swal.fire({
                                position: "top-right",
                                icon: "error",
                                title: "Please select atleast one item!",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        }

                    };

                    $scope.editOrderItem = function (item) {
                        $scope.orderItemObj = {};
                        $scope.orderItemObj.sku = item.sku;
                        $scope.orderItemObj.title = item.title;
                        $scope.orderItemObj.quantity = item.quantity;
                        $scope.orderItemObj.unitCost = item.unitCost;
                        $scope.orderItemObj.discount = item.discount;
                        $scope.orderItemObj.taxRate = item.taxRate;
                        $scope.orderItemObj.tax = item.tax;
                        $scope.orderItemObj.cost = item.cost;
                        $scope.orderItemObj.costIncTax = item.costIncTax;

                        var clonedata = clone($scope.rishvi.dto);
                        var convertDto = DotObject.object(clonedata);
                        var items = convertDto.items;

                        $scope.rishvi.dto = DotObject.object(clonedata);

                        $scope.rishvi.dto.orderItemObj = $scope.orderItemObj;
                        $scope.rishvi.dto.items = [];
                        $scope.rishvi.dto = DotObject.dot($scope.rishvi.dto);
                        $scope.rishvi.dto.items = items;

                        $('#editOrderIemModal').modal('show');
                    }

                    $scope.saveOrder = function () {
                        $scope.saveOrderDto = {};
                        $scope.saveOrderDto.orderId = $scope.rishvi.dto.orderId;
                        $scope.saveOrderDto.receivedDate = $scope.rishvi.dto.receivedDateString;
                        $scope.saveOrderDto.subSource = $scope.rishvi.dto.subSource;
                        $scope.saveOrderDto.status = $scope.rishvi.dto.status;
                        $scope.saveOrderDto.trackingNumber = $scope.rishvi.dto.trackingNumber;
                        $scope.saveOrderDto.postalServiceId = $scope.rishvi.dto.postalServiceId;
                        $scope.saveOrderDto.postageCost = $scope.rishvi.dto.postageCost;

                        $scope.saveOrderDto.sameAsShippingAddress = $scope.isBilling;

                        $scope.saveOrderDto.address = {};
                        $scope.saveOrderDto.address.emailAddress = $scope.rishvi.dto['shippingAddress.emailAddress'];
                        $scope.saveOrderDto.address.address1 = $scope.rishvi.dto['shippingAddress.address1'];
                        $scope.saveOrderDto.address.address2 = $scope.rishvi.dto['shippingAddress.address2'];
                        $scope.saveOrderDto.address.address3 = $scope.rishvi.dto['shippingAddress.address3'];
                        $scope.saveOrderDto.address.region = $scope.rishvi.dto['shippingAddress.region'];
                        $scope.saveOrderDto.address.town = $scope.rishvi.dto['shippingAddress.town'];
                        $scope.saveOrderDto.address.PostCode = $scope.rishvi.dto['shippingAddress.postCode'];
                        $scope.saveOrderDto.address.country = $(".shippingAddressCountryId option:selected").text();
                        $scope.saveOrderDto.address.countryId = $scope.rishvi.dto['shippingAddress.countryId'];
                        $scope.saveOrderDto.address.fullName = $scope.rishvi.dto['shippingAddress.fullName'];
                        $scope.saveOrderDto.address.company = $scope.rishvi.dto['shippingAddress.company'];
                        $scope.saveOrderDto.address.phoneNumber = $scope.rishvi.dto['shippingAddress.phoneNumber'];

                        $scope.saveOrderDto.billingAddress = {};
                        $scope.saveOrderDto.billingAddress.emailAddress = $scope.rishvi.dto['billingAddress.emailAddress'];
                        $scope.saveOrderDto.billingAddress.address1 = $scope.rishvi.dto['billingAddress.address1'];
                        $scope.saveOrderDto.billingAddress.address2 = $scope.rishvi.dto['billingAddress.address2'];
                        $scope.saveOrderDto.billingAddress.address3 = $scope.rishvi.dto['billingAddress.address3'];
                        $scope.saveOrderDto.billingAddress.region = $scope.rishvi.dto['billingAddress.region'];
                        $scope.saveOrderDto.billingAddress.town = $scope.rishvi.dto['billingAddress.town'];
                        $scope.saveOrderDto.billingAddress.PostCode = $scope.rishvi.dto['billingAddress.postCode'];
                        $scope.saveOrderDto.billingAddress.country = $(".billingAddressCountryId option:selected").text();
                        $scope.saveOrderDto.billingAddress.countryId = $scope.rishvi.dto['billingAddress.countryId'];
                        $scope.saveOrderDto.billingAddress.fullName = $scope.rishvi.dto['billingAddress.fullName'];
                        $scope.saveOrderDto.billingAddress.company = $scope.rishvi.dto['billingAddress.company'];
                        $scope.saveOrderDto.billingAddress.phoneNumber = $scope.rishvi.dto['billingAddress.phoneNumber'];

                        ordersService.orderEdit($scope.saveOrderDto).then(function (resp) {

                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 2000
                            });
                        });
                    }

                    $scope.editPackaging = function () {                        
                        $('#editPackagingModal').modal('show');
                    }

                    $scope.savePackaging = function () {
                        $scope.setOrderPackagingDto = {};
                        $scope.setOrderPackagingDto.fkPackagingGroupId = $scope.rishvi.dto.fkPackagingGroupId;
                        $scope.setOrderPackagingDto.fkPackagingTypeId = $scope.rishvi.dto.fkPackagingTypeId;

                        $scope.setOrderPackagingDto.totalWidth = $scope.rishvi.dto.packagingwidth;
                        $scope.setOrderPackagingDto.totalHeight = $scope.rishvi.dto.packagingheight;
                        $scope.setOrderPackagingDto.totalDepth = $scope.rishvi.dto.packagingdepth;

                        $scope.setOrderPackagingDto.totalWeight = $scope.rishvi.dto.packagingTotalWeight;
                        $scope.setOrderPackagingDto.pkOrderId = $scope.rishvi.dto.orderId;

                        ordersService.setOrderPackaging($scope.setOrderPackagingDto).then(function (resp) {

                            if (resp.data.success) {
                                $scope.rishvi.dto.totalWeight = $scope.setOrderPackagingDto.totalWeight;
                                $scope.rishvi.dto.itemWeight = $scope.setOrderPackagingDto.totalWeight;
                                $scope.rishvi.dto.dimension = $scope.setOrderPackagingDto.totalWidth + ' x ' + $scope.setOrderPackagingDto.totalHeight + ' x ' + $scope.setOrderPackagingDto.totalDepth; 
                            }
                            Swal.fire({
                                position: "top-right",
                                icon: resp.data.messageType === 1 ? "success" : "error",
                                title: resp.data.message,
                                showConfirmButton: false,
                                timer: 2000
                            });
                        });
                    }

                }]
        });

}(window.angular));
