(function (angular) {
    "use strict";

    angular.module('myApp').component('processOrderNote',
        {
            templateUrl: '/orders/views/processOrderNote.tpl.html',
            controller: ['$scope', '$state', 'rishviEdit', 'ordersService', '$filter',
                function ($scope, $state, rishviEdit, ordersService, $filter) {

                    
                }]
        });

}(window.angular));
