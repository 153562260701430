(function (angular) {
    'use strict';

    angular.module('myApp').config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('profitLoss',
                {
                    parent: 'system',
                    templateUrl: '/profitLoss/views/profitLoss.tpl.html'
                });
    }]);

})(angular);
