(function (angular) {
  "use strict";

    angular.module('myApp').component('outputCurrencyCreate', {
    templateUrl: '/outputCurrency/views/create.tpl.html',
    controller: 'outputCurrencyCreateController'
  });

  angular.module('myApp').controller('outputCurrencyCreateController',
    [
      '$scope', 'outputCurrencyService', 'rishviCreate',
      function ($scope, outputCurrencyService, rishviCreate) {

        $scope.rishvi = new rishviCreate();
        $scope.rishvi.service = outputCurrencyService;

        $scope.rishvi.listRoute = 'outputCurrency';
        $scope.rishvi.editRoute = 'outputCurrencyEdit';
        
      }
    ]);

}(window.angular));
