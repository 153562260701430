(function (angular) {
    "use strict";

    angular.module('myApp').component('pendingCalculator',
        {
            templateUrl: '/calculator/views/pendingCalculator.tpl.html',
            controller: ['$scope', '$state', 'rishviList', 'calculatorService', '$filter',
                function ($scope, $state, rishviList, calculatorService, $filter) {

                    $scope.list = new rishviList();
                    $scope.list.url = 'api/calculator/';
                    $scope.list.module = 'pendingCalculator';
                    $scope.list.filters.calculatorStatus = 0;
                    $scope.list.load();

                    $scope.pageSizeChanged = function (size) {
                        localStorageService.set('admin.page.size', size);
                    };

                    $scope.EditCalculator = function (id) {
                        $state.go("calculatorEdit", { id: id });
                    };

                }]
        });

}(window.angular));
