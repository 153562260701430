(function (angular) {
    "use strict";

    angular.module('myApp')
        .service('authService',
            [
                '$http', function ($http) {

                    var urlBase = 'api/auth/';

                    this.login = function (loginDto) {
                        return $http({
                            url: urlBase + 'login',
                            skipAuthorization: true,
                            method: 'POST',
                            data: loginDto
                        });
                    };

                    this.forgotPassword = function (adminForgotPasswordDto) {
                        return $http({
                            url: urlBase + 'forgot-password',
                            skipAuthorization: true,
                            method: 'POST',
                            data: adminForgotPasswordDto
                        });
                    };

                    this.resetPassword = function (token, adminResetPasswordDto) {
                        return $http({
                            url: urlBase + 'reset-password/' + token,
                            skipAuthorization: true,
                            method: 'POST',
                            data: adminResetPasswordDto
                        });
                    };
                }
            ]);

}(window.angular));
