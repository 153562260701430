(function (angular) {
    "use strict";

    angular.module('myApp').component('users',
        {
            templateUrl: '/users/views/usersList.tpl.html',
            controller: ['$scope', '$state', 'userService','$rootScope',
                function ($scope, $state, userService, $rootScope) {

                    $scope.userFilterDto = {};
                    $scope.load = function () {
                        if ($.fn.DataTable.isDataTable('#userList')) {
                            $('#userList').DataTable().clear().destroy();
                        }

                        userService.list($scope.userFilterDto).then(function (resp) {
                            $scope.users = resp.data.data;
                            BindDatable();
                        });
                    }

                    $scope.isEditUsersAccess = $rootScope.hasAccess("adminUsers.edit");
                    $scope.isDeleteUsersAccess = $rootScope.hasAccess("adminUsers.delete");

                    $scope.load();

                    //this.$onInit = function () {

                    //    //$scope.inventory();
                    //}

                    function BindDatable() {
                        setTimeout(function () {

                            var table = $('#userList');

                            table.DataTable({
                                pageLength: 10,
                                scrollY: '70vh',
                                scrollX: true,
                                scrollCollapse: true,
                                bProcessing: true,
                                destroy: true,
                                //bSort: true,
                                columnDefs: [{
                                    "targets": 'no-sort',
                                    "orderable": false
                                }],
                                buttons: [
                                    {
                                        extend: 'excel',
                                        filename: 'Users',
                                        title: '',
                                        exportOptions: {
                                            columns: [1, 2, 3, 4, 5, 6]
                                        }
                                    },
                                    {
                                        extend: 'csv',
                                        filename: 'Users',
                                        title: '',
                                        exportOptions: {
                                            columns: [1, 2, 3, 4, 5, 6]
                                        }
                                    }
                                ]
                            });

                        }, 500);
                    };

                    $scope.exportToExcel = function () {
                        $("#userList").DataTable().button('.buttons-excel').trigger();
                    }

                    $scope.exportToPdf = function () {
                        $("#userList").DataTable().button('.buttons-pdf').trigger();
                    }

                    $scope.exportToCsv = function () {
                        $("#userList").DataTable().button('.buttons-csv').trigger();
                    }

                    $scope.addUser = function () {
                        $state.go("usersCreate");
                    }

                    $scope.editUser = function (userId) {
                        $state.go("usersEdit", { id: userId });
                    }

                    $scope.delete = function (id) {
                        var ids = [id];
                        Swal.fire({
                            title: "Are you sure?",
                            text: "You want to delete User?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!"
                        }).then(function (result) {
                            if (result.isConfirmed) {
                                userService.delete(ids).then(function (resp) {
                                    var result = resp.data;

                                    Swal.fire({
                                        position: "top-right",
                                        icon: resp.data.messageType === 1 ? "success" : "error",
                                        title: resp.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    });
                                    if (result.success)
                                        $scope.load();
                                });
                            };
                        });
                    }

                }]
        });

}(window.angular));
