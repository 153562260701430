(function (angular) {
  'use strict';

  angular.module('myApp').config([
    '$stateProvider',
    function ($stateProvider) {

      $stateProvider.state('outputCurrency', {
          parent: 'screen',
          url: '/output-currency',
          component: 'outputCurrency',
          data: {
            permissions: ['outputCurrency']
          }
        })
        .state('outputCurrencyCreate', {
          parent: 'screen',
          url: '/output-currency/create',
          component: 'outputCurrencyCreate',
          data: {
            permissions: ['outputCurrency.create']
          }
        })
        .state('outputCurrencyEdit', {
          parent: 'screen',
          url: '/output-currency/edit/{id}',
          component: 'outputCurrencyEdit',
          data: {
            permissions: ['outputCurrency.edit']
          }
        });
    }
  ]);

})(angular);
